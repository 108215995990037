import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { DashboardInterface, DashboardState } from '../types';

export const initialState: DashboardState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,
  DashboardWallet: 0,
  DashboardFormData: {
    reportCount: 0,
    pendingReportCount: 0,
    amountApproved: 0,
    pendingAmountApproved: 0,
    employeeCount: 0
  },
  DashboardSearchList: {},
  DashboardGraphDataList: [],

  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  dateFilter: 0
};

export const useClinicSlice = createSlice({
  name: 'DashboardState',
  initialState,
  reducers: {
    doGetDashboardList: (state) => {},
    doGetWallet: (state) => {},
    doGetDashboardGraphData: (state, action: PayloadAction<any>) => {},
    setDashboardsList: (state, action: PayloadAction<DashboardInterface>) => {
      state.DashboardFormData = action.payload;
    },
    setGraphData: (state, action: PayloadAction<DashboardInterface>) => {
      // state.DashboardGraphDataList = action.payload;
      state.DashboardGraphDataList = action.payload;
    },
    setWalletData: (state, action: PayloadAction<DashboardInterface>) => {
      // state.DashboardGraphDataList = action.payload;
      state.DashboardWallet = action.payload;
    },
    setSearchData: (state, action: PayloadAction<DashboardInterface>) => {
      // state.DashboardGraphDataList = action.payload;
      state.DashboardSearchList = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      console.log('Loading');
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setDateFilter: (state, action: PayloadAction<number>) => {
      state.dateFilter = action.payload;
    },
    
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      console.log('pageSize');
      state.pageSize = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  CalendarOutlined,
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  KeyOutlined,
  DollarOutlined,
  AlignLeftOutlined,
  BookOutlined,
  ProfileOutlined,
  PieChartOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  TransactionOutlined,
  PartitionOutlined,
  IdcardOutlined
} from '@ant-design/icons';
// type
import { NavItemType } from '../types/menu';
import React from 'react';

// icons
const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  KeyOutlined,
  DollarOutlined,
  AlignLeftOutlined,
  BookOutlined,
  ProfileOutlined,
  PieChartOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  TransactionOutlined,
  PartitionOutlined,
  IdcardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const custom: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [

    {
      id: 'managereport',
      title: <FormattedMessage id="Report" />,
      type: 'collapse',
      icon: icons.BookOutlined,
      children: [
        {
          id: 'managereportlist',
          title: <FormattedMessage id="Manage Report" />,
          type: 'item',
          url: '/report/list'
        },
      ]
    },
    {
      id: 'managereportanalysis',
      title: <FormattedMessage id="Report Analysis" />,
      type: 'collapse',
      icon: icons.BookOutlined,
      children: [
        {
          id: 'managereportlistanalysis',
          title: <FormattedMessage id="Manage Report Analysis" />,
          type: 'item',
          url: '/report/allreport'
        },
        {
          id: 'topSpender',
          title: <FormattedMessage id="Top Spender" />,
          type: 'item',
          url: '/spender/list'
        },
      ]
    },
    {
      id: 'downloads',
      title: <FormattedMessage id="My Downloads" />,
      type: 'item',
      icon: icons.DashboardOutlined,
      url: '/report/downloads'
    }
  ]
};

export default custom;

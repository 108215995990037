import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from 'newTypes';

const selectDomain = (state: RootState) => {
  if (state && state.expensesState) {
    return state.expensesState;
  } else {
    return initialState;
  }
};
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectEditindex = createSelector([selectDomain], (state) => state.editIndex);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const selectExpenseForm = createSelector([selectDomain], (state) => state.reportExpenseForm);
export const selectExpenseList = createSelector([selectDomain], (state) => state.reportExpenseList);
export const selectReportData = createSelector([selectDomain], (state) => state.reportData);
export const selectAnotherLoading = createSelector([selectDomain], (state) => state.anotherLoading);
export const selectReportCommentList = createSelector([selectDomain], (state) => state.reportCommentList);
export const selectExpenseCommentList = createSelector([selectDomain], (state) => state.expenseCommentList);
export const SelectExpenseLoading = createSelector([selectDomain], (state) => state.expenseLoading);
export const SelectReportPageNo = createSelector([selectDomain], (state) => state.reportPageNo);
export const SelectReportPageSize = createSelector([selectDomain], (state) => state.reportPageSize);
export const SelectReportTotalRow = createSelector([selectDomain], (state) => state.reportTotalRow);
export const selectReportHasMore = createSelector([selectDomain], (state) => state.reportHasMore);
export const SelectExpensePageNo = createSelector([selectDomain], (state) => state.expensePageNo);
export const SelectExpensePageSize = createSelector([selectDomain], (state) => state.expensePageSize);
export const SelectExpenseTotalRow = createSelector([selectDomain], (state) => state.expenseTotalRow);
export const selectExpenseHasMore = createSelector([selectDomain], (state) => state.expenseHasMore);
export const selectSendBackToEmployeeForm = createSelector([selectDomain], (state) => state.sendBackToEmployeeForm);
export const selectApproverFlowList = createSelector([selectDomain], (state) => state.approverFlowList);
export const selectModalLoading = createSelector([selectDomain], (state) => state.modalLoading);
export const selectCommentLog = createSelector([selectDomain], (state) => state.commentLog);
export const SelectCurrentErrorField = createSelector([selectDomain], (state) => state.currentErrorField);
export const SelectPdfContent = createSelector([selectDomain], (state) => state.pdfContent);
export const SelectPdfName = createSelector([selectDomain], (state) => state.pdfName);

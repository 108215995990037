export interface OrganizationDetailInterface {
  _id: string;
  name: string;
  businessPlace: string;
  description: string;
  supplyPlace: string;
  sectionCode: string;
  organizationId: string;
  isActive: IsActivityEnum;
}
export interface CostCentertAllInterface {
  _id: string;
  costCenter: string;
  name: string;
}
export enum IsActivityEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export interface OrganizationDetailState {
  list: OrganizationDetailInterface[];
  form: OrganizationDetailInterface;
  listAll: CostCentertAllInterface[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  currentErrorField: number[];
}

export type InitialOrganizationDetailState = OrganizationDetailState;

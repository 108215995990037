import { PayloadAction } from '@reduxjs/toolkit';
import { LoginMessage } from 'Messages/LoginMessage';
import { createSlice } from '../../../utils copy/@reduxjs/toolkit';
import { AuthState, CodeVerificationInterface, ENUM_ROLE_TYPE } from '../types';
import { set } from 'lodash';

export const initialState: AuthState = {
  loading: false,
  loginForm: { username: '', password: '', errors: [], success: '' },
  userData: {
    name: '',
    email: '',
    usertype: ENUM_ROLE_TYPE.ADMIN,
    profilePic: '',
    importDefaultStatus: false,
    costCenter: '',
    costCenterName: '',
  },
  token: localStorage.getItem('token') || '',
  isLogin: true,
  updatePasswordFormData: { id: '', confirmPassword: '', password: '' },
  forgetPasswordFormData: {
    id: '',
    email: ''
  },
  resetEmailAddress: '',
  resetPasswordFormData: {
    id: '',
    password: '',
    confirmPassword: ''
  },
  codeVerificationForm: {
    hash: '',
    otp: ''
  },
  userEmail: '',
  buttonLoading: false
};

export const useClinicSlice = createSlice({
  name: 'AuthState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setUpdateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },

    setisLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setProfileData: (
      state,
      action: PayloadAction<{
        name: string;
        importDefaultStatus: boolean;
        email: string;
        usertype: ENUM_ROLE_TYPE;
        profilePic: string;
        costCenter: string;
        costCenterName: string;
      }>
    ) => {
      state.userData.name = action.payload.name;
      state.userData.email = action.payload.email;
      state.userData.profilePic = action.payload.profilePic;
      state.userData.usertype = action.payload.usertype;
      state.userData.importDefaultStatus = action.payload.importDefaultStatus;
      state.userData.costCenter = action.payload.costCenter;
      state.userData.costCenterName = action.payload.costCenterName;
    },
    getUserProfile: (state) => {},

    doLogin: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.loading = true;
      state.loginForm.errors = [];
    },
    //Working
    doLogOut: (state) => {
      state.isLogin = true;
    },
    addLoginError: (state, action: PayloadAction<string>) => {
      state.loginForm.errors.push(action.payload);
    },

    loginSuccess: (state) => {
      state.loginForm.success = LoginMessage.LOGIN_SUCCESS_MESSAGE;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `loginForm.${action.payload.key}`, action.payload.value);
      state.loginForm.errors = [];
    },
    userUpdatePasswordFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `updatePasswordFormData.${action.payload.key}`, action.payload.value);
    },
    doUpdatePassword: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearPasswordForm: (state) => {
      state.updatePasswordFormData.id = '';
      state.updatePasswordFormData.password = '';
      state.updatePasswordFormData.confirmPassword = '';
    },

    updateForgetPasswordFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `forgetPasswordFormData.${action.payload.key}`, action.payload.value);
      state.loginForm.errors = [];
    },
    doSendForgetPassword: (state, action: PayloadAction<{ callback: () => void }>) => {},

    clearForgetPasswordForm: (state) => {
      state.forgetPasswordFormData.id = '';
      state.forgetPasswordFormData.email = '';
    },

    doSetResetEmailAddress: (state, action: PayloadAction<any>) => {
      state.resetEmailAddress = action.payload;
    },
    updateResetPasswordFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `resetPasswordFormData.${action.payload.key}`, action.payload.value);
    },
    updateResetPassword: (state, action: PayloadAction<{ token: string; callback: () => void }>) => {},
    clearResetPasswordFrom: (state) => {
      state.resetPasswordFormData.id = '';
      state.resetPasswordFormData.password = '';
      state.resetPasswordFormData.confirmPassword = '';
    },
    updateCodeVerificationFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `codeVerificationForm.${action.payload.key}`, action.payload.value);
    },
    doVerifyCode: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetDetails: (state, action: PayloadAction<{ id: string; callback: () => void }>) => {},
    clearCodeVerificationFrom: (state) => {
      state.codeVerificationForm.hash = '';
      state.codeVerificationForm.otp = '';
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
    },
    setCodeVerificationFormData: (state, action: PayloadAction<CodeVerificationInterface>) => {
      state.codeVerificationForm = action.payload;
    },
    // doResendVerificationCode: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doSendVerificationCode: (state, action: PayloadAction<{ callback: (hash: string) => void }>) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

export interface OrganizationInterface {
  id: string;
  _id: string;
  email: string;
  name: string;
  address: string;
  panNumber: string;
  gstNumber: string;
  mobileNumber: string;
  isActive: IsActivityEnum;
  createdAt: string;
  updatedAt: string;
  employeeCount: number;
  reportCount: number;
}
export enum IsActivityEnum {
  INACTIVE = 2,
  ACTIVE = 1
}
export interface OrganizationState {
  list: OrganizationInterface[];
  AnalyticsList: OrganizationInterface[];
  form: OrganizationInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  currentErrorField: number[];
}

export type InitialOrganizationState = OrganizationState;

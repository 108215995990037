import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from 'newTypes';

const selectDomain = (state: RootState) => {
  if (state && state.reportState) {
    return state.reportState;
  } else {
    return initialState;
  }
};
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectEditindex = createSelector([selectDomain], (state) => state.editIndex);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const selectDepartmentList = createSelector([selectDomain], (state) => state.departmentList);
export const selectSubDepartmentList = createSelector([selectDomain], (state) => state.subDepartmentList);
export const selectDepartmentForm = createSelector([selectDomain], (state) => state.departmentForm);
export const selectAvailabeExpenseList = createSelector([selectDomain], (state) => state.availabeExpenseList);
export const selectAvailabeExpenseForm = createSelector([selectDomain], (state) => state.availabeExpenseForm);
export const selectDepartmentData = createSelector([selectDomain], (state) => state.departmentData);
export const selectExpenseSearch = createSelector([selectDomain], (state) => state.availabeExpenseForm.expenseSearch);
export const selectApproverList = createSelector([selectDomain], (state) => state.approverList);
export const selectExpenseLoading = createSelector([selectDomain], (state) => state.expenseLoading);
export const SelectCurrentErrorField = createSelector([selectDomain], (state) => state.currentErrorField);
export const SelectReportCountData = createSelector([selectDomain], (state) => state.reportCountData);
export const selectSelectExpenseType = createSelector([selectDomain], (state) => state.selectExpenseType);


export const selectDownLoadList = createSelector([selectDomain], (state) => state.downLoadList);
export const selectEmployeeId = createSelector([selectDomain], (state) => state.employeeId);
export const selectReportNumber = createSelector([selectDomain], (state) => state.reportNumber);
export const selectStartDate = createSelector([selectDomain], (state) => state.form.startDate);
export const selectEndDate = createSelector([selectDomain], (state) => state.form.endDate);
export const selectState = createSelector([selectDomain], (state) => state.state);
export const selectIdArray = createSelector([selectDomain], (state) => state.idArray);
export const selectName = createSelector([selectDomain], (state) => state.name);
export const selectQueryListData = createSelector([selectDomain], (state) => state.QueryListData);
// project import
import Routes from './routes';
import ThemeCustomization from './themes';
import Locales from './components/Locales';
import RTLLayout from './components/RTLLayout';
import ScrollTop from './components/ScrollTop';
// import Snackbar from './components/@extended/Snackbar';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { FirebaseProvider as AuthProvider } from './contexts/FirebaseContext';
import { sliceKey as MenusliceKey, reducer as menuReducer } from './store/reducers/menu';
import { sliceKey as SnackbarsliceKey, reducer as SnackbarReducer } from './store/reducers/snackbar';
import { sliceKey as AuthsliceKey, reducer as AuthReducer } from './sections/auth/redux/slice';
import { authRepoSaga } from './sections/auth/redux/saga';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
import { sliceKey as DashboardSliceKey, reducer as DashboardReducer } from 'pages/dashboard/redux/slice';
import { authRepoSagaDashboard } from 'pages/dashboard/redux/saga';
import { sliceKey as RoleUsersliceKey, reducer as RoleUserReducer } from 'sections/apps/profiles/account/redux/slice';
import { authRepoSagaRoleUser } from 'sections/apps/profiles/account/redux/saga';

import { sliceKey as OrganizationSliceKey, reducer as OrganizationReducer } from 'pages/organizationList/redux/slice';
import { OrganizationRepoSaga } from 'pages/organizationList/redux/saga'; //organization
import { sliceKey as ExpensesSliceKey, reducer as ExpensesReducer } from 'pages/expenses/redux/slice';
import { ExpensesRepoSaga } from 'pages/expenses/redux/saga'; //Expenses
import { sliceKey as DesignationSliceKey, reducer as DesignationReducer } from 'pages/post/redux/slice';
import { DesignationRepoSaga } from 'pages/post/redux/saga'; //Post
import { sliceKey as EmployeeSliceKey, reducer as EmployeeReducer } from 'pages/employee/redux/slice';
import { EmployeeRepoSaga } from 'pages/employee/redux/saga'; //Employee
import { sliceKey as DepartmentSliceKey, reducer as DepartmentReducer } from 'pages/department/redux/slice';
import { DepartmentRepoSaga } from 'pages/department/redux/saga'; //Department
import { sliceKey as SubDepartmentSliceKey, reducer as SubDepartmentReducer } from 'pages/subDepartment/redux/slice';
import { SubDepartmentRepoSaga } from 'pages/subDepartment/redux/saga'; //subDepartment
import { sliceKey as ReportSliceKey, reducer as ReportReducer } from 'pages/report/redux/slice';
import { ReportRepoSaga } from 'pages/report/redux/saga'; //report
import { sliceKey as MyClaimSliceKey, reducer as MyClaimReducer } from 'pages/myClaims/redux/slice';

import { MyClaimRepoSaga } from 'pages/myClaims/redux/saga'; //MyClaim
import { sliceKey as LevelSliceKey, reducer as LevelReducer } from 'pages/levelSection/redux/slice';
import { LevelRepoSaga } from 'pages/levelSection/redux/saga';
import { sliceKey as ExpenseLevelSliceKey, reducer as ExpenseLevelReducer } from 'pages/expenseLevel/redux/slice';

import { ExpenseLevelRepoSaga } from 'pages/expenseLevel/redux/saga';
import { sliceKey as GradeSliceKey, reducer as GradeReducer } from 'pages/Grade/redux/slice';
import { GradeRepoSaga } from 'pages/Grade/redux/saga';
import { sliceKey as ExportToExcelSliceKey, reducer as ExportToExcelReducer } from 'pages/ExcelFile/redux/slice';
import { ExportToExcelRepoSaga } from 'pages/ExcelFile/redux/saga';
import { sliceKey as OrganizationDetailSliceKey, reducer as OrganizationDetailReducer } from 'pages/organizationDetail/redux/slice';
import { OrganizationDetailRepoSaga } from 'pages/organizationDetail/redux/saga';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionTimeout from 'components/SessionTimeout';

const App = () => {
  useInjectReducer({ key: SnackbarsliceKey, reducer: SnackbarReducer });
  useInjectReducer({ key: MenusliceKey, reducer: menuReducer });
  useInjectReducer({ key: AuthsliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthsliceKey, saga: authRepoSaga });

  //******Order List Section******//
  useInjectReducer({ key: DashboardSliceKey, reducer: DashboardReducer });
  useInjectSaga({ key: DashboardSliceKey, saga: authRepoSagaDashboard });

  useInjectReducer({ key: RoleUsersliceKey, reducer: RoleUserReducer });
  useInjectSaga({ key: RoleUsersliceKey, saga: authRepoSagaRoleUser }); //dashboard

  useInjectReducer({ key: OrganizationSliceKey, reducer: OrganizationReducer });
  useInjectSaga({ key: OrganizationSliceKey, saga: OrganizationRepoSaga }); //organization
  useInjectReducer({ key: ExpensesSliceKey, reducer: ExpensesReducer });
  useInjectSaga({ key: ExpensesSliceKey, saga: ExpensesRepoSaga }); //Expenses
  useInjectReducer({ key: DesignationSliceKey, reducer: DesignationReducer });
  useInjectSaga({ key: DesignationSliceKey, saga: DesignationRepoSaga }); //Designation
  useInjectReducer({ key: EmployeeSliceKey, reducer: EmployeeReducer });
  useInjectSaga({ key: EmployeeSliceKey, saga: EmployeeRepoSaga }); //Employee
  useInjectReducer({ key: DepartmentSliceKey, reducer: DepartmentReducer });
  useInjectSaga({ key: DepartmentSliceKey, saga: DepartmentRepoSaga }); //Department
  useInjectReducer({ key: SubDepartmentSliceKey, reducer: SubDepartmentReducer });
  useInjectSaga({ key: SubDepartmentSliceKey, saga: SubDepartmentRepoSaga }); //SubDepartment
  useInjectReducer({ key: ReportSliceKey, reducer: ReportReducer });
  useInjectSaga({ key: ReportSliceKey, saga: ReportRepoSaga }); //Report
  useInjectReducer({ key: MyClaimSliceKey, reducer: MyClaimReducer });
  useInjectSaga({ key: MyClaimSliceKey, saga: MyClaimRepoSaga }); //MyClaim
  useInjectReducer({ key: LevelSliceKey, reducer: LevelReducer });
  useInjectSaga({ key: LevelSliceKey, saga: LevelRepoSaga }); //Level
  useInjectReducer({ key: ExpenseLevelSliceKey, reducer: ExpenseLevelReducer });
  useInjectSaga({ key: ExpenseLevelSliceKey, saga: ExpenseLevelRepoSaga }); //ExpenseLevel
  useInjectReducer({ key: GradeSliceKey, reducer: GradeReducer });
  useInjectSaga({ key: GradeSliceKey, saga: GradeRepoSaga }); //Grade
  useInjectReducer({ key: ExportToExcelSliceKey, reducer: ExportToExcelReducer });
  useInjectSaga({ key: ExportToExcelSliceKey, saga: ExportToExcelRepoSaga }); //Excel List
  useInjectReducer({ key: OrganizationDetailSliceKey, reducer: OrganizationDetailReducer });
  useInjectSaga({ key: OrganizationDetailSliceKey, saga: OrganizationDetailRepoSaga }); //Organization Detail List

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Routes />
                {/* <Snackbar /> */}
                <ToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                  transition={Bounce}
                />
                <SessionTimeout />
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;

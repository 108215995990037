// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  CalendarOutlined,
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  KeyOutlined,
  DollarOutlined,
  AlignLeftOutlined,
  BookOutlined,
  ProfileOutlined,
  PieChartOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  TransactionOutlined,
} from '@ant-design/icons';
// type
import { NavItemType } from '../types/menu';
import React from 'react';

// icons
const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  KeyOutlined,
  DollarOutlined,
  AlignLeftOutlined,
  BookOutlined,
  ProfileOutlined,
  PieChartOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  TransactionOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const custom: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'OrganizationAnalytics',
      title: <FormattedMessage id="Organization Analytics" />,
      type: 'item',
      icon: icons.AlignLeftOutlined,
      url: '/organization/analytics'
    },
    {
      id: 'organization',
      title: <FormattedMessage id="Organization" />,
      type: 'collapse',
      icon: icons.AlignLeftOutlined,
      children: [
        {
          id: 'oranizationlist',
          title: <FormattedMessage id="Organization List" />,
          type: 'item',
          url: '/organization/list'
        }
      ]
    },
    {
      id: 'expenses',
      title: <FormattedMessage id="Expenses" />,
      type: 'collapse',
      icon: icons.TransactionOutlined,
      children: [
        {
          id: 'expenseslist',
          title: <FormattedMessage id="Expenses List" />,
          type: 'item',
          url: '/expenses/list'
        }
      ]
    },
  ]
};

export default custom;

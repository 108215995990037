import { toast } from 'react-toastify';
import errorCode from './ErrorCode/Index';

export default function CatchBlockFunction(error: any) {
  if (errorCode(error?.response?.data.statusCode)) {
    toast.error(error?.response?.data.message);
    return;
  } else {
    if (error.message !== 'e is undefined') {
      if (error.message !== "undefined is not an object (evaluating 'e.context')") {
        if (error.message !== "Cannot read properties of undefined (reading 'context')") {
          if (error.message !== "Cannot read properties of undefined (reading '0')") {
            if (error.message !== 'call: argument fn is undefined or null') {
              if (error.message !== "Cannot read properties of undefined (reading 'results')") {
                if (error.message !== 'call: argument of type {context, fn} has undefined or null `fn`') {
                  if (error.message !== "Cannot read properties of undefined (reading 'apply')") {
                    if (error.response) {
                      if (error.response.status !== 401) {
                        if (error.response.data.errors) {
                          let dataerrer3 = error.response.data.errors.map((item: any) => {
                            return {
                              name: item.property,
                              message: item.constraints.isNotEmpty
                                ? item.constraints.isNotEmpty
                                : item.constraints.isNumber
                                ? item.constraints.isNumber
                                : item.constraints.isString
                                ? item.constraints.isString
                                : item.constraints.matches
                                ? item.constraints.matches
                                : ''
                            };
                          });
                          for (let index = 0; index < dataerrer3.length; index++) {
                            const element = dataerrer3[index];
                            toast.error(element.message);
                          }
                        } else {
                          toast.error(error.response.data.message);
                        }
                      } else {
                        localStorage.removeItem('token');
                      }
                    } else {
                      toast.error(error.message);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

import { PayloadAction } from '@reduxjs/toolkit';
import {
  MyClaimInterface,
  MyClaimState,
  CurrencyEnum,
  ReportStateEnum,
  ReportStateFilterEnum,
} from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';
import { CountryEnum } from 'pages/report/types';
import { ENUM_ROLE_TYPE } from 'sections/auth/types';

export const initialState: MyClaimState = {
  loading: false,
  form: {
    id: '',
    _id: '',
    name: '',
    statusContent: "",
    date: '',
    country: CountryEnum.INDIA,
    department: '',
    subDepartment: '',
    costCenter: '',
    businessPurpose: '',
    amountAdjusted: 0,
    foreignCurrencyAmount: 0,
    currency: CurrencyEnum.INR,
    comment: '',
    state: ReportStateEnum.DRAFT,
    departmentName: '',
    subDepartmentName: '',
    reportNumber: '',
    startDate: '',
    endDate: ''
  },
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: '',
  state: ReportStateFilterEnum.All,
  employeeId: '',
  reportNumber: '',
  sentForPaymentList: [],
  topSpenderList: [],
  roleState: ENUM_ROLE_TYPE.ALL
};

export const useClinicSlice = createSlice({
  name: 'myClaimState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setEmployeeId: (state, action: PayloadAction<string>) => {
      state.employeeId = action.payload;
    },
    setReportNumber: (state, action: PayloadAction<string>) => {
      state.reportNumber = action.payload;
    },
    setState: (state, action: PayloadAction<ReportStateFilterEnum>) => {
      state.state = action.payload;
    },
    setRoles: (state, action: PayloadAction<ENUM_ROLE_TYPE>) => {
      state.roleState = action.payload;
    },

    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearForm: (state) => {
      state.form.id = '';
      state.form._id = '';
      state.form.name = '';
      state.form.date = '';
      state.form.currency = CurrencyEnum.INR;
      state.form.country = CountryEnum.INDIA;
      state.form.costCenter = '';
      state.form.department = '';
      state.form.departmentName = '';
      state.form.subDepartment = '';
      state.form.subDepartmentName = '';
      state.form.businessPurpose = '';
      state.form.amountAdjusted = 0;
      state.form.foreignCurrencyAmount = 0;
      state.form.comment = '';
      state.form.state = ReportStateEnum.DRAFT;
      
    },
    doGetList: (state) => {},
    doGetTopSpenderList: (state) => {},
    doGetListMakeForPayment: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    
    setList: (state, action: PayloadAction<Array<MyClaimInterface>>) => {
      state.list = action.payload;
    },
    setTopSpenderList: (state, action: PayloadAction<Array<any>>) => {
      state.topSpenderList = action.payload;
    },
    setSentForPaymentList: (state, action: PayloadAction<Array<MyClaimInterface>>) => {
      state.sentForPaymentList = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<MyClaimInterface>) => {
      state.form = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    updateDateFilterFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  UserOutlined,
  TransactionOutlined,
  PartitionOutlined,
  IdcardOutlined,
  AppstoreOutlined,
  StockOutlined,
  AuditOutlined,
  ProfileOutlined,
  BookOutlined
} from '@ant-design/icons';
// type
import { NavItemType } from '../types/menu';
import React from 'react';

// icons
const icons = {
  UserOutlined,
  TransactionOutlined,
  PartitionOutlined,
  IdcardOutlined,
  AppstoreOutlined,
  StockOutlined,
  AuditOutlined,
  ProfileOutlined,
  BookOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const custom: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'level',
      title: <FormattedMessage id="Level" />,
      type: 'collapse',
      icon: icons.StockOutlined,
      children: [
        {
          id: 'levellist',
          title: <FormattedMessage id="Level List" />,
          type: 'item',
          url: '/level/list'
        },
        {
          id: 'expenseLevellist',
          title: <FormattedMessage id="Expense Level List" />,
          type: 'item',
          url: '/expenselevel/list'
        }
      ]
    },
    {
      id: 'grade',
      title: <FormattedMessage id="Grade" />,
      type: 'collapse',
      icon: icons.ProfileOutlined,
      children: [
        {
          id: 'gradelist',
          title: <FormattedMessage id="GradeList" />,
          type: 'item',
          url: '/gradelist/list'
        }
      ]
    },
    {
      id: 'expenses',
      title: <FormattedMessage id="Expenses" />,
      type: 'collapse',
      icon: icons.TransactionOutlined,
      children: [
        {
          id: 'expenseslist',
          title: <FormattedMessage id="Manage Expenses" />,
          type: 'item',
          url: '/expenses/list'
        }
      ]
    },

    {
      id: 'department',
      title: <FormattedMessage id="Department" />,
      type: 'collapse',
      icon: icons.AppstoreOutlined,
      children: [
        {
          id: 'departmentlist',
          title: <FormattedMessage id="Department List" />,
          type: 'item',
          url: '/department/list'
        }
      ]
    },
    {
      id: 'subdepartment',
      title: <FormattedMessage id="Sub Department" />,
      type: 'collapse',
      icon: icons.PartitionOutlined,
      children: [
        {
          id: 'subdepartmentlist',
          title: <FormattedMessage id="Sub Department List" />,
          type: 'item',
          url: '/subdepartment/list'
        }
      ]
    },
    {
      id: 'designation',
      title: <FormattedMessage id="Designation" />,
      type: 'collapse',
      icon: icons.IdcardOutlined,
      children: [
        {
          id: 'designationlist',
          title: <FormattedMessage id="Manage Designation" />,
          type: 'item',
          url: '/designation/list'
        }
      ]
    },
    {
      id: 'details',
      title: <FormattedMessage id="Cost" />,
      type: 'collapse',
      icon: icons.AuditOutlined,
      children: [
        {
          id: 'detailslist',
          title: <FormattedMessage id="Manage Cost" />,
          type: 'item',
          url: '/details/list'
        }
      ]
    },
    {
      id: 'employees',
      title: <FormattedMessage id="Employees" />,
      type: 'collapse',
      icon: icons.UserOutlined,
      children: [
        {
          id: 'employeeslist',
          title: <FormattedMessage id="Manage Employees" />,
          type: 'item',
          url: '/employee/list'
        }
      ]
    },
    {
      id: 'managereport',
      title: <FormattedMessage id="Report" />,
      type: 'collapse',
      icon: icons.BookOutlined,
      children: [
        {
          id: 'managereportlist',
          title: <FormattedMessage id="Manage Report" />,
          type: 'item',
          url: '/report/allreport'
        },
        {
          id: 'topSpender',
          title: <FormattedMessage id="Top Spender" />,
          type: 'item',
          url: '/spender/list'
        },
      ]
    },

  ]
};

export default custom;

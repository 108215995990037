import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { ExpensesInterface, ReportExpensesInterface, sendBackToEmployeeInterface } from '../types';
import {
  selectForm,
  SelectPageNo,
  SelectPageSize,
  SelectSearch,
  SelectSortColumn,
  SelectSortDir,
  selectExpenseForm,
  selectReportCommentList,
  SelectReportPageNo,
  SelectReportPageSize,
  selectExpenseCommentList,
  SelectExpensePageNo,
  SelectExpensePageSize,
  selectSendBackToEmployeeForm
} from './selector';
import {
  doAddExpense,
  doGetExpensesList,
  doGetExpenseEdit,
  doUpdateExpense,
  doAddImportDefaultExpenses,
  doAddReporting,
  doGetReportingList,
  doGetReportingEdit,
  doUpdateReporting,
  doGetReceiptImageRequest,
  doCopyExpense,
  doSubmitReport,
  doDeleteExpense,
  doGetReportCommentList,
  doGetExpenseCommentList,
  doRecallReport,
  doSendBackToEmployee,
  doAppoverFlowList,
  doUplodeProfilePicRequest,
  doGetReportSummary,
  doGetPdfDownload
} from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { toast } from 'react-toastify';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ExpensesInterface = yield select(selectForm);

  if (form.validityDays > 90 || form.title.length === 0) {
    if (form.title.length === 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please fill expense title');
    }
    if (form.validityDays > 90) {
      yield put(actions.setCurrentErrorField(3));
      toast.error('Validity should be less than 90 days');
    }

    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddExpense, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetExpensesList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetExpenseEdit, action.payload);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ExpensesInterface = yield select(selectForm);

  if (form.validityDays > 90 || form.title.length === 0 || (form.gradeSystemApplied == 1 && form.expenseGrades.length == 0)) {
    if (form.title.length === 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please fill expense title');
    }
    if (form.validityDays > 90) {
      yield put(actions.setCurrentErrorField(3));
      toast.error('Validity should be less than 90 days');
    }
    if (form.gradeSystemApplied == 1 && form.expenseGrades.length == 0) {
      yield put(actions.setCurrentErrorField(4));
      yield put(actions.setCurrentErrorField(5));
      toast.error('Please Add at least 1 grade');
      return;
    }
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateExpense, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doAddImportDefaultRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ExpensesInterface = yield select(selectForm);
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddImportDefaultExpenses, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doAddGradeRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
//Report Expense
export function* doAddExpenseReportRequest(action: { payload: { type: number; callback: any } }) {
  yield delay(500);
  const form: ReportExpensesInterface = yield select(selectExpenseForm);

  if (
    form.transactionDate.length == 0 ||
    form.location.length == 0 ||
    form.amount <= 0 ||
    (form.receiptRequired == 1 && form.receiptFile.length == 0)
  ) {
    if (form.transactionDate.length == 0) {
      yield put(actions.setCurrentErrorField(6));
      toast.error('Please select transaction date');
    }
    if (form.location.length == 0) {
      yield put(actions.setCurrentErrorField(7));
      toast.error('Please fill location');
    }
    if (form.amount <= 0) {
      yield put(actions.setCurrentErrorField(8));
      toast.error('Please fill amount');
    }
    if (form.receiptRequired == 1 && form.receiptFile.length == 0) {
      yield put(actions.setCurrentErrorField(9));
      toast.error('Please select a receipt file');
    }
    return;
  }

  if (action.payload.type == 1) {
    yield put(actions.setLoading(true));
  } else if (action.payload.type == 2) {
    yield put(actions.setAnotherLoading(true));
  }
  try {
    const response: AxiosResponse = yield call(doAddReporting, form);
    if (action.payload.type == 1) {
      yield put(actions.setLoading(false));
    } else if (action.payload.type == 2) {
      yield put(actions.setAnotherLoading(false));
    }
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    if (action.payload.type == 1) {
      yield put(actions.setLoading(false));
    } else if (action.payload.type == 2) {
      yield put(actions.setAnotherLoading(false));
    }

    CatchBlockFunction(error);
  }
}
export function* doGetExpenseReportListRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);

  try {
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetReportingList,
      `?reportId=${action.payload.id}${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setReportExpenseList(response.data.data[0].results));
    yield put(actions.setReportData(response.data.data[0].reportData[0]));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetExpenseReportEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetReportingEdit, action.payload);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setReportExpenseDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateExpenseReportRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: ReportExpensesInterface = yield select(selectExpenseForm);

  if (form.transactionDate.length == 0 || form.location.length == 0 || (form.receiptRequired == 1 && form.receiptFile.length == 0)) {
    if (form.transactionDate.length == 0) {
      yield put(actions.setCurrentErrorField(6));
      toast.error('Please select transaction date');
    }
    if (form.location.length == 0) {
      yield put(actions.setCurrentErrorField(7));
      toast.error('Please fill location');
    }
    if (form.receiptRequired == 1 && form.receiptFile.length == 0) {
      yield put(actions.setCurrentErrorField(9));
      toast.error('Please select a receipt file');
    }
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateReporting, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* FileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetReceiptImageRequest, action.payload.data);
    if (!response.status) {
      toast.error(response.data.message);
      return;
    }
    yield call(action?.payload?.callback(response.data.signedUrl, response.data.fileNameWithPrefix));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: { payload: { data: any; signedUrl: string; result: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      doUplodeProfilePicRequest,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    if (!response.status) {
      toast.error(response.data.message);
      return;
    } else {
      toast.success(response.data.message);
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doCopyExpenseRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);

  if (action.payload.id.length == 0) {
    toast.error('Please select an expense');
    return;
  }
  try {
    yield put(actions.setModalLoading(true));
    const response: AxiosResponse = yield call(doCopyExpense, action.payload.id);
    yield put(actions.setModalLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setModalLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doSubmitReportRequest(action: { payload: { id: any; callback: any } }) {
  yield delay(500);

  try {
    yield put(actions.setModalLoading(true));
    const response: AxiosResponse = yield call(doSubmitReport, action.payload.id);
    yield put(actions.setModalLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setModalLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doDeleteExpenseRequest(action: { payload: { id: any; callback: any } }) {
  yield delay(500);

  try {
    yield put(actions.setModalLoading(true));
    const response: AxiosResponse = yield call(doDeleteExpense, action.payload.id);
    yield put(actions.setModalLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setModalLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doRecallReportRequest(action: { payload: { id: any; callback: any } }) {
  yield delay(500);

  try {
    yield put(actions.setModalLoading(true));
    const response: AxiosResponse = yield call(doRecallReport, action.payload.id);
    yield put(actions.setModalLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setModalLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doAddSendBackToEmployeeCommentRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: sendBackToEmployeeInterface = yield select(selectSendBackToEmployeeForm);

  if (form.message.length == 0) {
    toast.error('Please fill message');
    return;
  }
  try {
    yield put(actions.setModalLoading(true));
    const response: AxiosResponse = yield call(doSendBackToEmployee, form);
    yield put(actions.setModalLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setModalLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doApproverListRequest(action: { payload: { id: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doAppoverFlowList, action.payload.id);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield put(actions.setApproverFlowList(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

//comment log section
export function* doGetReportCommentListRequest(action: { payload: { id: string; pageNo?: number } }) {
  yield delay(500);

  try {
    const commentList: [] = yield select(selectReportCommentList);
    let pageNo: number = yield select(SelectReportPageNo);
    let pageSize: number = yield select(SelectReportPageSize);
    if (action.payload.pageNo) {
      pageNo = action.payload.pageNo;
    }
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doGetReportCommentList, `${action.payload.id}?pageNo=${pageNo}&pageSize=${pageSize}`);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    if (pageNo == 1) {
      yield put(actions.setReportTotalRow(response.data.data[0].meta.total));
      yield put(actions.setReportCommentList(response.data.data[0].results));
    } else {
      const concatdata = commentList.concat(response.data.data[0].results);
      yield put(actions.setReportTotalRow(response.data.data[0].meta.total));
      yield put(actions.setReportCommentList(concatdata));
    }
    yield put(actions.setReportHasMore(response.data.data[0].results.length > 0));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetExpenseCommentListRequest(action: { payload: { id: string; pageNo?: number; callback: any } }) {
  yield delay(500);

  try {
    const commentList: [] = yield select(selectExpenseCommentList);
    let pageNo: number = yield select(SelectExpensePageNo);
    let pageSize: number = yield select(SelectExpensePageSize);
    if (action.payload.pageNo) {
      pageNo = action.payload.pageNo;
    }

    yield put(actions.setExpenseLoading(true));
    const response: AxiosResponse = yield call(doGetExpenseCommentList, `${action.payload.id}?pageNo=${pageNo}&pageSize=${pageSize}`);
    yield put(actions.setExpenseLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    if (pageNo == 1) {
      yield put(actions.setExpenseTotalRow(response.data.data[0].meta.total));
      yield put(actions.setExpenseCommentList(response.data.data[0].results));
    } else {
      const concatdata = commentList.concat(response.data.data[0].results);
      yield put(actions.setExpenseTotalRow(response.data.data[0].meta.total));
      yield put(actions.setExpenseCommentList(concatdata));
    }
    yield put(actions.setExpenseHasMore(response.data.data[0].results.length > 0));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setExpenseLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetAllReportCommentRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doGetReportSummary, action.payload.id);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setCommentLog(response.data.data[0].results));

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetAllPDFDataRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doGetPdfDownload, action.payload.id);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setPdfContent(response.data.data.pdfContent));
    yield put(actions.setPDFName(`${response.data.data.reportName}-${response.data.data.reportNumber}.pdf`));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* ExpensesRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doAddImportDefaul, doAddImportDefaultRequest);
  yield takeLatest(actions.doAddGrade, doAddGradeRequest);
  yield takeLatest(actions.doAddReportExpense, doAddExpenseReportRequest);
  yield takeLatest(actions.doGetReportExpenseList, doGetExpenseReportListRequest);
  yield takeLatest(actions.doGetReportExpenseEdit, doGetExpenseReportEditRequest);
  yield takeLatest(actions.doUpdateReportExpense, doUpdateExpenseReportRequest);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadeImage, UploadRequest);
  yield takeLatest(actions.doCopyExpense, doCopyExpenseRequest);
  yield takeLatest(actions.doSubmitReport, doSubmitReportRequest);
  yield takeLatest(actions.doDeleteExpense, doDeleteExpenseRequest);
  yield takeLatest(actions.doRecallReport, doRecallReportRequest);
  //comment list
  yield takeLatest(actions.doGetReportCommentList, doGetReportCommentListRequest);
  yield takeLatest(actions.doGetExpenseCommentList, doGetExpenseCommentListRequest);
  yield takeLatest(actions.doAddSendBackToEmployee, doAddSendBackToEmployeeCommentRequest);
  yield takeLatest(actions.doGetApproverFlowList, doApproverListRequest);
  yield takeLatest(actions.doGetAllReportComment, doGetAllReportCommentRequest);
  yield takeLatest(actions.doGetAllPDFData, doGetAllPDFDataRequest);
}

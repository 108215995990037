import { lazy } from 'react';

// project import
import MainLayout from '../layout/MainLayout';
import CommonLayout from '../layout/CommonLayout';
import Loadable from '../components/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/default')));
const DashboardAnalytics = Loadable(lazy(() => import('../pages/dashboard/analytics')));
const Settings = Loadable(lazy(() => import('../pages/Controls/Settings')));
const AppContactUS = Loadable(lazy(() => import('../pages/contact-us')));
const UserProfile = Loadable(lazy(() => import('../pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));
const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));
const OrganizationList = Loadable(lazy(() => import('../pages/organizationList/list')));
const OrganizationAnalyticsList = Loadable(lazy(() => import('../pages/organizationList/analyticsList')));
// const AddOrganization = Loadable(lazy(() => import('../pages/organizationList/form')));
const ExpensesList = Loadable(lazy(() => import('../pages/expenses/list')));
const Downloads = Loadable(lazy(() => import('../pages/report/downloads')));
const AddExpense2 = Loadable(lazy(() => import('../pages/expenses/form2')));
const ViewReportExpensesList = Loadable(lazy(() => import('../pages/expenses/viewReportExpenses')));
const DesignationList = Loadable(lazy(() => import('../pages/post/list')));
// const AddDesignation = Loadable(lazy(() => import('../pages/post/form')));
const EmployeeList = Loadable(lazy(() => import('../pages/employee/list')));
const AddEmployee = Loadable(lazy(() => import('../pages/employee/form')));
const ReportList = Loadable(lazy(() => import('../pages/report/list')));
const AddReport = Loadable(lazy(() => import('../pages/report/form')));
const ManageReport = Loadable(lazy(() => import('../pages/report/ManageReport')));
const ApproverReportList = Loadable(lazy(() => import('../pages/report/PastReports')));
const DepartmentList = Loadable(lazy(() => import('../pages/department/list')));
// const AddDepartment = Loadable(lazy(() => import('../pages/department/form')));
const SubDepartmentList = Loadable(lazy(() => import('../pages/subDepartment/list')));
const MyClaimforMoney = Loadable(lazy(() => import('../pages/myClaims/myClaimforMoney')));
const MyClaimsList = Loadable(lazy(() => import('../pages/myClaims/list')));
const PendingReportList = Loadable(lazy(() => import('../pages/report/PendingReports')));
const TopSpenderList = Loadable(lazy(() => import('../pages/TopSpender/index')));
const PendingReportsForOrgnization = Loadable(lazy(() => import('../pages/report/PendingReportsForOrgnization')));
// level section
// const AddLevel = Loadable(lazy(() => import('../pages/levelSection/form')));
const LevelList = Loadable(lazy(() => import('../pages/levelSection/list')));
const ExpenseLevelList = Loadable(lazy(() => import('../pages/expenseLevel/list')));
const GradeList = Loadable(lazy(() => import('../pages/Grade/list')));
const ExcelList = Loadable(lazy(() => import('../pages/ExcelFile/list')));
const ExcelListData = Loadable(lazy(() => import('../pages/ExcelFile/excelData')));
const OrganizationDetailList = Loadable(lazy(() => import('../pages/organizationDetail/list')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'default',
              element: <DashboardDefault />
            },
            {
              path: 'analytics',
              element: <DashboardAnalytics />
            }
          ]
        },
        {
          path: 'organization',
          children: [
            {
              path: 'list',
              element: <OrganizationList />
            },
            {
              path: 'analytics',
              element: <OrganizationAnalyticsList />
            },
            
          ]
        },
        {
          path: 'spender',
          children: [
            {
              path: 'list',
              element: <TopSpenderList />
            }
          ]
        },
        {
          path: 'expenses',
          children: [
            {
              path: 'list',
              element: <ExpensesList />
            },
            {
              path: 'addform2/:id/:rid',
              element: <AddExpense2 />
            },
            {
              path: 'addform2/:expId/:rid/expense',
              element: <AddExpense2 />
            },
            {
              path: 'addform2',
              element: <AddExpense2 />
            },
            {
              path: 'viewreportexpenseslist',
              element: <ViewReportExpensesList />
            },
            {
              path: 'viewreportexpenseslist/:id',
              element: <ViewReportExpensesList />
            }
          ]
        },
        {
          path: 'designation',
          children: [
            {
              path: 'list',
              element: <DesignationList />
            }
          ]
        },
        {
          path: 'level',
          children: [
            {
              path: 'list',
              element: <LevelList />
            }
          ]
        },
        {
          path: 'expenselevel',
          children: [
            {
              path: 'list',
              element: <ExpenseLevelList />
            }
          ]
        },
        {
          path: 'details',
          children: [
            {
              path: 'list',
              element: <OrganizationDetailList />
            }
          ]
        },
        {
          path: 'employee',
          children: [
            {
              path: 'list',
              element: <EmployeeList />
            },
            {
              path: 'addform/:id',
              element: <AddEmployee />
            },
            {
              path: 'addform',
              element: <AddEmployee />
            }
          ]
        },
        {
          path: 'gradelist',
          children: [
            {
              path: 'list',
              element: <GradeList />
            }
          ]
        },
        {
          path: 'department',
          children: [
            {
              path: 'list',
              element: <DepartmentList />
            }
          ]
        },
        {
          path: 'subdepartment',
          children: [
            {
              path: 'list',
              element: <SubDepartmentList />
            }
          ]
        },
        {
          path: 'report',
          element: <ManageReport />,
          children: [
            {
              path: 'list',
              element: <ReportList />
            },
            {
              path: 'myclaimslist',
              element: <MyClaimsList />
            },
            {
              path: 'mysentformoneylist',
              element: <MyClaimforMoney />
            }
          ]
        },
        {
          path: 'report',
          children: [
            {
              path: 'allreport',
              element: <PendingReportsForOrgnization />
            },
            {
              path: 'addform/:id',
              element: <AddReport />
            },
            {
              path: 'downloads',
              element: <Downloads />
            },
            {
              path: 'addform',
              element: <AddReport />
            },
            {
              path: 'approverreportlist',
              element: <ApproverReportList />
            },
            {
              path: 'pendingreportlist',
              element: <PendingReportList />
            }            
          ]
        },
        {
          path: 'managereport',
          children: [
            {
              path: 'list',
              element: <ReportList />
            }
          ]
        },
        {
          path: 'excelFile',
          children: [
            {
              path: 'list',
              element: <ExcelList />
            },
            {
              path: 'exceldata',
              element: <ExcelList />
            },
            {
              path: 'exceldata/:id',
              element: <ExcelListData />
            }
          ]
        },
        {
          path: 'controls',
          children: [
            {
              path: 'settings',
              element: <Settings />
            }
          ]
        },
        {
          path: 'apps',
          children: [
            {
              path: 'profiles',
              children: [
                {
                  path: 'account',
                  element: <AccountProfile />,
                  children: [
                    {
                      path: 'basic',
                      element: <AccountTabProfile />
                    },
                    {
                      path: 'personal',
                      element: <AccountTabPersonal />
                    },
                    {
                      path: 'my-account',
                      element: <AccountTabAccount />
                    },
                    {
                      path: 'password',
                      element: <AccountTabPassword />
                    },
                    {
                      path: 'role',
                      element: <AccountTabRole />
                    },
                    {
                      path: 'settings',
                      element: <AccountTabSettings />
                    }
                  ]
                },
                {
                  path: 'user',
                  element: <UserProfile />,
                  children: [
                    {
                      path: 'personal',
                      element: <UserTabPersonal />
                    },
                    {
                      path: 'payment',
                      element: <UserTabPayment />
                    },
                    {
                      path: 'password',
                      element: <UserTabPassword />
                    },
                    {
                      path: 'settings',
                      element: <UserTabSettings />
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: 'contact-us',
          element: <AppContactUS />
        }
      ]
    }
  ]
};

export default MainRoutes;

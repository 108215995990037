import { PayloadAction } from '@reduxjs/toolkit';
import { EmployeeFileContentStatusEnum, ExcelDataInterface, ExcelDetailsDataInterface, ExportToExcelState } from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';

export const initialState: ExportToExcelState = {
  loading: false,
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: '',
  pageNoData: 1,
  pageSizeData: 10,
  totalRowData: 0,
  sortColumnData: '',
  sortDirData: '',
  detailsList: [],
  filterStatus: EmployeeFileContentStatusEnum.ALL
};

export const useClinicSlice = createSlice({
  name: 'exportToExcelState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPageSizeDetails: (state, action: PayloadAction<number>) => {
      state.pageSizeData = action.payload;
    },
    setPageNoDetails: (state, action: PayloadAction<number>) => {
      state.pageNoData = action.payload;
    },
    setTotalRowDetails: (state, action: PayloadAction<number>) => {
      state.totalRowData = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setFilterStatus: (state, action: PayloadAction<EmployeeFileContentStatusEnum>) => {
      state.filterStatus = action.payload;
    },
    doGetList: (state) => {},
    doGetListById: (state, action: PayloadAction<string>) => {},

    setList: (state, action: PayloadAction<Array<ExcelDataInterface>>) => {
      state.list = action.payload;
    },
    setListById: (state, action: PayloadAction<Array<ExcelDetailsDataInterface>>) => {
      state.detailsList = action.payload;
    },

    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setSortColumnDetails: (state, action: PayloadAction<string>) => {
      state.sortColumnData = action.payload;
    },
    setSortDirDetails: (state, action: PayloadAction<string>) => {
      state.sortDirData = action.payload;
    },
    // excel Import
    doExcelImport: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    doUploadeExcelImport: (state, action: PayloadAction<{ data: any; signedUrl: string; result: any; callback: any }>) => {},
    doAddExcelImport: (state, action: PayloadAction<{ fileName: string; originalFileName: string; callback: () => void }>) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

import { PayloadAction } from '@reduxjs/toolkit';
import {
  ReceiptEnum,
  IsActivityEnum,
  ExpensesInterface,
  ExpensesState,
  PaymentTypeEnum,
  ReportStateEnum,
  ReceiptRequiredEnum,
  ReportExpensesInterface,
  CurrencyEnum,
  ReportDataInterface,
  gradeSystemAppliedEnum,
  CommentListInterface,
  ApproverFlowListInterface,
  LevelBypassEnum
} from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';
import { ENUM_ROLE_TYPE } from 'sections/auth/types';

export const initialState: ExpensesState = {
  loading: false,
  form: {
    id: '',
    _id: '',
    title: '',
    receiptRequired: ReceiptEnum.YES,
    isActive: IsActivityEnum.ACTIVE,
    grade: '',
    gradeId: '',
    expenseGradeForm: {
      _id: '',
      id: '',
      grade: '',
      gradeId: '',
      maxPrice: 0,
      action: ''
    },
    expenseGrades: [],
    validityDays: 0,
    gradeSystemApplied: gradeSystemAppliedEnum.NO,
    expneseLevelId: '',
    expneseLevelName: '',
    glCode: '',
    levelBypass: LevelBypassEnum.NO,
  },
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: '',
  reportExpenseForm: {
    id: '',
    fileName:"",
    _id: '',
    validityDays: 0,
    expenseId: '',
    expense: '',
    transactionDate: '',
    vendorName: '',
    location: '',
    paymentType: PaymentTypeEnum.CASH,
    amount: 0,
    currency: CurrencyEnum.INR,
    receiptRequired: ReceiptRequiredEnum.NO_RECEIPT,
    travelReason: '',
    comment: '',
    state: ReportStateEnum.DRAFT,
    receiptFile: '',
    reportId: '',
    noReceiptReason: '',
    message: '',
    expenseAmount: 0
  },
  reportExpenseList: [],
  reportData: {
    _id: '',
    report: '',
    statusContent: '',
    reportNumber: '',
    expenseTotalAmount: 0,
    state: ReportStateEnum.DRAFT,
    role: ENUM_ROLE_TYPE.EMPLOYEE,
    myReport: false,
    attachmentCount: 0
  },
  anotherLoading: false,
  reportCommentList: [],
  expenseCommentList: [],
  expenseLoading: false,
  reportPageNo: 1,
  reportPageSize: 10,
  reportTotalRow: 0,
  expensePageNo: 1,
  expensePageSize: 10,
  expenseTotalRow: 0,
  reportHasMore: false,
  expenseHasMore: false,
  sendBackToEmployeeForm: {
    message: '',
    _id: ''
  },
  approverFlowList: [],
  modalLoading: false,
  commentLog: [],
  commentLogPageNo: 0,
  commentLogPageSize: 0,
  commentLogTotalRow: 0,
  commentLogHasMore: false,
  currentErrorField: [],
  pdfContent:[],
  pdfName:""
};

export const useClinicSlice = createSlice({
  name: 'expensesState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAnotherLoading: (state, action: PayloadAction<boolean>) => {
      state.anotherLoading = action.payload;
    },
    setModalLoading: (state, action: PayloadAction<boolean>) => {
      state.modalLoading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setPdfContent: (state, action: PayloadAction<string[]>) => {
      state.pdfContent = action.payload;
    },
    setPDFName: (state, action: PayloadAction<string>) => {
      state.pdfName = action.payload;
    },
    setCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (!state.currentErrorField.includes(action.payload)) {
        state.currentErrorField.push(action.payload);
      }
    },
    removeCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (state.currentErrorField.includes(action.payload)) {
        let index = state.currentErrorField.indexOf(action.payload);
        state.currentErrorField.splice(index, 1);
      }
    },
    clearCurrentErrorField: (state) => {
      state.currentErrorField = [];
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    clearForm: (state) => {
      state.form.id = '';
      state.form._id = '';
      state.form.title = '';
      state.form.glCode = '';
      state.form.receiptRequired = ReceiptEnum.YES;
      state.form.isActive = IsActivityEnum.ACTIVE;
      state.form.grade = '';
      state.form.gradeId = '';
      state.form.expenseGrades = [];
      state.form.validityDays = 0;
      state.form.levelBypass = LevelBypassEnum.NO;
      state.form.gradeSystemApplied = gradeSystemAppliedEnum.NO;
      state.form.expneseLevelId = '';
      state.form.expenseGradeForm.grade = '';
      state.form.expenseGradeForm.gradeId = '';
      state.form.expenseGradeForm.maxPrice = 0;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setList: (state, action: PayloadAction<Array<ExpensesInterface>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<ExpensesInterface>) => {
      state.form.id = action.payload.id;
      state.form._id = action.payload._id;
      state.form.title = action.payload.title;
      state.form.glCode = action.payload.glCode;
      state.form.receiptRequired = action.payload.receiptRequired;
      state.form.isActive = action.payload.isActive;
      state.form.expenseGrades = action.payload.expenseGrades;
      state.form.validityDays = action.payload.validityDays;
      state.form.gradeSystemApplied = action.payload.gradeSystemApplied;
      state.form.expneseLevelId = action.payload.expneseLevelId;
      state.form.expneseLevelName = action.payload.expneseLevelName;
      state.form.levelBypass = action.payload.levelBypass;
      state.form.grade = '';
      state.form.gradeId = '';
      state.form.expenseGradeForm.grade = '';
      state.form.expenseGradeForm.gradeId = '';
      state.form.expenseGradeForm.maxPrice = 0;
    },
    doAddImportDefaul: (state, action: PayloadAction<{ callback: () => void }>) => {},

    updateExpenseFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `reportExpenseForm.${action.payload.key}`, action.payload.value);
    },
    doAddReportExpense: (state, action: PayloadAction<{ type: number; callback: () => void }>) => {},
    doGetReportExpenseList: (state, actions: PayloadAction<{ id: string; callback: any }>) => {},
    doGetAllReportComment: (state, actions: PayloadAction<{ id: string; callback: any }>) => {},
    doGetReportExpenseEdit: (state, action: PayloadAction<string>) => {},
    doUpdateReportExpense: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setReportExpenseList: (state, action: PayloadAction<Array<ReportExpensesInterface>>) => {
      state.reportExpenseList = action.payload;
    },
    setReportExpenseDataForEdit: (state, action: PayloadAction<ReportExpensesInterface>) => {
      state.reportExpenseForm = action.payload;
    },
    setCommentLog: (state, action: PayloadAction<any>) => {
      state.commentLog = action.payload;
    },
    doGetAllPDFData: (state, actions: PayloadAction<{ id: string; callback: any }>) => {},
    clearExpenseForm: (state) => {
      state.reportExpenseForm.id = '';
      state.reportExpenseForm._id = '';
      state.reportExpenseForm.expenseId = '';
      state.reportExpenseForm.expense = '';
      state.reportExpenseForm.transactionDate = '';
      state.reportExpenseForm.vendorName = '';
      state.reportExpenseForm.location = '';
      state.reportExpenseForm.paymentType = PaymentTypeEnum.CASH;
      state.reportExpenseForm.amount = 0;
      state.reportExpenseForm.currency = CurrencyEnum.INR;
      state.reportExpenseForm.receiptRequired = ReceiptRequiredEnum.RECEIPT;
      state.reportExpenseForm.travelReason = '';
      state.reportExpenseForm.comment = '';
      state.reportExpenseForm.receiptFile = '';
      state.reportExpenseForm.reportId = '';
      state.reportExpenseForm.noReceiptReason = '';
      state.reportExpenseForm.validityDays = 0;
      state.reportExpenseForm.message = '';
      state.reportExpenseForm.expenseAmount = 0;
      state.reportExpenseForm.state = ReportStateEnum.DRAFT;
    },
    clearReportExpenseFormExceptReportId: (state) => {
      state.reportExpenseForm.id = '';
      state.reportExpenseForm._id = '';
      state.reportExpenseForm.expenseId = '';
      state.reportExpenseForm.expense = '';
      state.reportExpenseForm.transactionDate = '';
      state.reportExpenseForm.vendorName = '';
      state.reportExpenseForm.location = '';
      state.reportExpenseForm.paymentType = PaymentTypeEnum.CASH;
      state.reportExpenseForm.amount = 0;
      state.reportExpenseForm.currency = CurrencyEnum.INR;
      state.reportExpenseForm.receiptRequired = ReceiptRequiredEnum.RECEIPT;
      state.reportExpenseForm.travelReason = '';
      state.reportExpenseForm.comment = '';
      state.reportExpenseForm.receiptFile = '';
      state.reportExpenseForm.noReceiptReason = '';
      state.reportExpenseForm.validityDays = 0;
      state.reportExpenseForm.message = '';
      state.reportExpenseForm.expenseAmount = 0;
      state.reportExpenseForm.state = ReportStateEnum.DRAFT;
    },
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    UploadeImage: (state, action: PayloadAction<{ data: any; signedUrl: string; result: any; callback: any }>) => {},
    doCopyExpense: (state, action: PayloadAction<{ id: string; callback: any }>) => {},

    doGetReportData: (state) => {},
    doGetPDFMargeData: (state) => {},
    setReportData: (state, action: PayloadAction<ReportDataInterface>) => {
      state.reportData = action.payload;
    },
    doSubmitReport: (state, action: PayloadAction<{ id: any; callback: any }>) => {},
    doRecallReport: (state, action: PayloadAction<{ id: any; callback: any }>) => {},
    doDeleteExpense: (state, action: PayloadAction<{ id: any; callback: any }>) => {},

    updateGradeFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.expenseGradeForm.${action.payload.key}`, action.payload.value);
    },
    doAddGrade: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.form.expenseGrades.push(state.form.expenseGradeForm);
    },

    doDeleteGrade: (state, action: PayloadAction<number>) => {
      if (state.form.expenseGrades[action.payload]._id?.length > 0) {
        state.form.expenseGrades[action.payload].action = 'delete';
      } else {
        state.form.expenseGrades.splice(action.payload, 1);
      }
    },
    clearGradeForm: (state) => {
      state.form.expenseGradeForm.grade = '';
      state.form.expenseGradeForm.gradeId = '';
      state.form.expenseGradeForm.maxPrice = 0;
    },

    setExpenseLoading: (state, action: PayloadAction<boolean>) => {
      state.expenseLoading = action.payload;
    },
    setReportPageSize: (state, action: PayloadAction<number>) => {
      state.reportPageSize = action.payload;
    },
    setReportPageNo: (state, action: PayloadAction<number>) => {
      state.reportPageNo = action.payload;
    },
    setReportTotalRow: (state, action: PayloadAction<number>) => {
      state.reportTotalRow = action.payload;
    },
    setReportHasMore: (state, action: PayloadAction<boolean>) => {
      state.reportHasMore = action.payload;
    },
    doGetReportCommentList: (state, action: PayloadAction<{ id: string; pageNo?: number }>) => {},
    setReportCommentList: (state, action: PayloadAction<Array<CommentListInterface>>) => {
      state.reportCommentList = action.payload;
    },

    setExpensePageSize: (state, action: PayloadAction<number>) => {
      state.expensePageSize = action.payload;
    },
    setExpensePageNo: (state, action: PayloadAction<number>) => {
      state.expensePageNo = action.payload;
    },
    setExpenseTotalRow: (state, action: PayloadAction<number>) => {
      state.expenseTotalRow = action.payload;
    },
    setCommentLogPageSize: (state, action: PayloadAction<number>) => {
      state.commentLogPageSize = action.payload;
    },
    setCommentLogPageNo: (state, action: PayloadAction<number>) => {
      state.commentLogPageNo = action.payload;
    },
    setCommentLogHasMore: (state, action: PayloadAction<boolean>) => {
      state.commentLogHasMore = action.payload;
    },
    setCommentLogTotalRow: (state, action: PayloadAction<number>) => {
      state.commentLogTotalRow = action.payload;
    },
    setExpenseHasMore: (state, action: PayloadAction<boolean>) => {
      state.expenseHasMore = action.payload;
    },
    doGetExpenseCommentList: (state, action: PayloadAction<{ id: string; pageNo?: number; callback: any }>) => {},
    setExpenseCommentList: (state, action: PayloadAction<Array<CommentListInterface>>) => {
      state.expenseCommentList = action.payload;
    },

    updateSendBackToEmployeeFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `sendBackToEmployeeForm.${action.payload.key}`, action.payload.value);
    },
    clearSendBackToEmployeeForm: (state) => {
      state.sendBackToEmployeeForm._id = '';
      state.sendBackToEmployeeForm.message = '';
    },
    doAddSendBackToEmployee: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    doGetApproverFlowList: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    setApproverFlowList: (state, action: PayloadAction<Array<ApproverFlowListInterface>>) => {
      state.approverFlowList = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

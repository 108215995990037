import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { OrganizationDetailInterface } from '../types';
import { selectForm, SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import { doAddCostCenter, doGetCostCenterList, doGetCostCenterEdit, doUpdateCostCenter, doGetAllCostCenter } from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { toast } from 'react-toastify';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: OrganizationDetailInterface = yield select(selectForm);

  if (
    form.name.length === 0 ||
    form.businessPlace.length === 0 ||
    form.description.length === 0 ||
    form.supplyPlace.length === 0 ||
    form.sectionCode.length === 0
  ) {
    if (form.name.length === 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please fill name');
    }
    if (form.businessPlace.length === 0) {
      yield put(actions.setCurrentErrorField(2));
      toast.error('Please fill business place');
    }
    if (form.description.length === 0) {
      yield put(actions.setCurrentErrorField(3));
      toast.error('Please fill description');
    }
    if (form.supplyPlace.length === 0) {
      yield put(actions.setCurrentErrorField(4));
      toast.error('Please fill Place Of Supply');
    }
    if (form.sectionCode.length === 0) {
      yield put(actions.setCurrentErrorField(5));
      toast.error('Please fill section code');
    }
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddCostCenter, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const response: AxiosResponse = yield call(
      doGetCostCenterList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetCostCenterEdit, action.payload);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: OrganizationDetailInterface = yield select(selectForm);

  if (
    form.name.length === 0 ||
    form.businessPlace.length === 0 ||
    form.description.length === 0 ||
    form.supplyPlace.length === 0 ||
    form.sectionCode.length === 0
  ) {
    if (form.name.length === 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please fill name');
    }
    if (form.businessPlace.length === 0) {
      yield put(actions.setCurrentErrorField(2));
      toast.error('Please fill business place');
    }
    if (form.description.length === 0) {
      yield put(actions.setCurrentErrorField(3));
      toast.error('Please fill description');
    }
    if (form.supplyPlace.length === 0) {
      yield put(actions.setCurrentErrorField(4));
      toast.error('Please fill Place Of Supply');
    }
    if (form.sectionCode.length === 0) {
      yield put(actions.setCurrentErrorField(5));
      toast.error('Please fill section code');
    }
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateCostCenter, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetDepartmentAllRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetAllCostCenter);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setAllCostCenterList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* OrganizationDetailRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doGetAllCostCenter, doGetDepartmentAllRequest);
}

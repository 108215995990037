import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from 'newTypes';

const selectDomain = (state: RootState) => {
  if (state && state.employeeState) {
    return state.employeeState;
  } else {
    return initialState;
  }
};
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectEditindex = createSelector([selectDomain], (state) => state.editIndex);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectSortDir = createSelector([selectDomain], (state) => state.sortDir);
export const SelectSortColumn = createSelector([selectDomain], (state) => state.sortColumn);
export const selectDesignationList = createSelector([selectDomain], (state) => state.designationlist);
export const selectAllLevelList = createSelector([selectDomain], (state) => state.allLevelList);
export const selectApproverList = createSelector([selectDomain], (state) => state.approverlist);
export const selectLevelApproverList = createSelector([selectDomain], (state) => state.form.levelApprover);
export const selectLevelApproverForm = createSelector([selectDomain], (state) => state.form.levelApproverForm);
export const SelectCurrentErrorField = createSelector([selectDomain], (state) => state.currentErrorField);

export interface DepartmentInterface {
  id: string;
  _id:string;
  department: string;
  isActive: IsActivityEnum;
}
export enum IsActivityEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export interface DepartmentState {
  list: DepartmentInterface[];
  form: DepartmentInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  currentErrorField: number[];
}

export type InitialDepartmentState = DepartmentState;

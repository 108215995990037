import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import {
  SelectFilterStatus,
  SelectPageNo,
  SelectPageNoData,
  SelectPageSize,
  SelectPageSizeData,
  SelectSearch,
  SelectSortColumn,
  SelectSortColumnData,
  SelectSortDir,
  SelectSortDirData
} from './selector';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { doAddExcelImport, doExcelImportRequest, doGetExcelList, doGetExcelListById, doUplodeProfilePicRequest } from 'utils copy/request';
import { toast } from 'react-toastify';

export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const sortColumn: string = yield select(SelectSortColumn);
    const sortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetExcelList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''
      }${sortDir.length > 0 ? '&sortDir=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListAllRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNoData);
    const pageSize: number = yield select(SelectPageSizeData);
    const searchText: string = yield select(SelectSearch);
    const sortColumn: string = yield select(SelectSortColumnData);
    const sortDir: string = yield select(SelectSortDirData);
    const filterStatus: string = yield select(SelectFilterStatus);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetExcelListById,
      action.payload,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''
      }${sortDir.length > 0 ? '&sortDir=' + sortDir : ''}&status=${filterStatus}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRowDetails(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setListById(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
// excel import
export function* FileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doExcelImportRequest, action.payload.data);
    yield put(actions.setLoading(false));
    if (!response.status) {
      toast.error(response.data.message);
      return;
    }
    yield call(action?.payload?.callback(response.data.signedUrl, response.data.fileNameWithPrefix));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: { payload: { data: any; signedUrl: string; result: any; callback: any } }) {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doUplodeProfilePicRequest,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    yield put(actions.setLoading(false));
    if (!response.status) {
      toast.error(response.data.message);
      return;
    } else {
      toast.success(response.data.message);
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
    yield put(actions.setLoading(false));
  }
}
export function* doAddExcelImportRequest(action: { payload: { fileName: string; originalFileName: string; callback: any } }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doAddExcelImport, {
      fileName: action.payload.fileName,
      originalFileName: action.payload.originalFileName
    });
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* ExportToExcelRepoSaga() {
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetListById, doGetListAllRequest);
  // excel import
  yield takeLatest(actions.doExcelImport, FileUploadRequest);
  yield takeLatest(actions.doUploadeExcelImport, UploadRequest);
  yield takeLatest(actions.doAddExcelImport, doAddExcelImportRequest);
}

import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { ReportInterface } from '../types';
import {
  selectForm,
  SelectPageNo,
  SelectPageSize,
  SelectSearch,
  SelectSortColumn,
  SelectSortDir,
  selectExpenseSearch,
  selectEmployeeId,
  selectReportNumber,
  selectState,
  selectIdArray,
  selectSelectExpenseType,
  selectName
} from './selector';
import {
  doAddReport,
  doGetReportList,
  doGetReportEdit,
  doUpdateReport,
  doGetDepartmentRequest,
  doGetSubDepartmentRequest,
  doGetAvailableExpensesList,
  doGetAvailableExpensesListData,
  doGetDepartmentData,
  doGetAvailableExpensesList2,
  doGetApproverReportList,
  doApprovedReport,
  doGetDownloadList,
  doGetReportCount,
  doReportCopy,
  doGetReportOrganizationList,
  doAddQuery,
  doGetQueryList
} from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import moment from 'moment';
import { toast } from 'react-toastify';
import { actions as expenseActions } from 'pages/expenses/redux/slice';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ReportInterface = yield select(selectForm);

  if (form.name.length === 0 || form.date.length === 0 || form.businessPurpose.length === 0) {
    if (form.name.length === 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please fill name');
    }
    if (form.date.length === 0) {
      yield put(actions.setCurrentErrorField(2));
      toast.error('Please fill date');
    }
    if (form.businessPurpose.length === 0) {
      yield put(actions.setCurrentErrorField(3));
      toast.error('Please fill business purpose');
    }
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddReport, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);

  try {
    const form: ReportInterface = yield select(selectForm);
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const employeeId: string = yield select(selectEmployeeId);
    const reportNumber: string = yield select(selectReportNumber);
    const state: string = yield select(selectState);
    const response: AxiosResponse = yield call(
      doGetReportList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        employeeId.length > 0 ? '&employeeID=' + employeeId : ''
      }${reportNumber.length > 0 ? '&reportNumber=' + reportNumber : ''}&startDate=${
        form.startDate > 0 ? moment(form.startDate).format('MM-DD-YYYY') : ''
      }&endDate=${form.endDate > 0 ? moment(form.endDate).format('MM-DD-YYYY') : ''}${state.length > 0 ? '&state=' + state : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetReportEdit, action.payload);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ReportInterface = yield select(selectForm);

  if (form.name.length === 0 || form.date.length === 0 || form.businessPurpose.length === 0) {
    if (form.name.length === 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please fill name');
    }
    if (form.date.length === 0) {
      yield put(actions.setCurrentErrorField(2));
      toast.error('Please fill date');
    }
    if (form.businessPurpose.length === 0) {
      yield put(actions.setCurrentErrorField(3));
      toast.error('Please fill business purpose');
    }
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateReport, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetDepartmentListRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetDepartmentRequest);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setDepartmentList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetSubDepartmentListRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetSubDepartmentRequest, `?departmentId=${action.payload}`);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setSubDepartmentList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetAvailableExpensesListRequest() {
  yield delay(500);

  try {
    const searchText: string = yield select(selectExpenseSearch);
    yield put(actions.setExpenseLoading(true));
    const response: AxiosResponse = yield call(doGetAvailableExpensesList, `?${searchText?.length > 0 ? 'search=' + searchText : ''}`);
    yield put(actions.setExpenseLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setAvailableExpensesList(response.data.data));
  } catch (error: any) {
    yield put(actions.setExpenseLoading(false));
    if (error.message != "Request failed with status code 403") {
       CatchBlockFunction(error);
    }
  }
}
export function* doGetAvailableExpensesListWithoutFilterRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doGetAvailableExpensesList2);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setAvailableExpensesListWithoutFilter(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetAvailableExpensesListDataRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);
  try {
    yield put(actions.setExpenseLoading(true));
    const response: AxiosResponse = yield call(doGetAvailableExpensesListData, action?.payload.id);
    yield put(actions.setExpenseLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setAvailableExpensesDataForEdit(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setExpenseLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetDepartmentDataRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetDepartmentData);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setSubDepartmentData(response.data.data[0]));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetApproverReportListRequest() {
  yield delay(500);

  try {
    const form: ReportInterface = yield select(selectForm);
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const employeeId: string = yield select(selectEmployeeId);
    const reportNumber: string = yield select(selectReportNumber);
    const state: string = yield select(selectState);
    const response: AxiosResponse = yield call(
      doGetApproverReportList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        employeeId.length > 0 ? '&employeeID=' + employeeId : ''
      }${reportNumber.length > 0 ? '&reportNumber=' + reportNumber : ''}&startDate=${
        form.startDate > 0 ? moment(form.startDate).format('MM-DD-YYYY') : ''
      }&endDate=${form.endDate > 0 ? moment(form.endDate).format('MM-DD-YYYY') : ''}${state.length > 0 ? '&state=' + state : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setApproverReportList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doApproveReportRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const list: string[] = yield select(selectIdArray);
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doApprovedReport, list);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetDownloadsRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const response: AxiosResponse = yield call(
      doGetDownloadList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setDownLoadList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetReportCountDataRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetReportCount);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setReportCountData(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doCopyReportRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const list: string[] = yield select(selectIdArray);

  yield put(expenseActions.setModalLoading(true));
  try {
    const response: AxiosResponse = yield call(doReportCopy, list);
    yield put(expenseActions.setModalLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(expenseActions.setModalLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListOrganizationRequest() {
  yield delay(500);
  try {
    const form: ReportInterface = yield select(selectForm);
    const expenseList: string[] = yield select(selectSelectExpenseType);
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const employeeId: string = yield select(selectEmployeeId);
    const reportNumber: string = yield select(selectReportNumber);
    const state: string = yield select(selectState);
    const response: AxiosResponse = yield call(
      doGetReportOrganizationList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        employeeId.length > 0 ? '&employeeID=' + employeeId : ''
      }${reportNumber.length > 0 ? '&reportNumber=' + reportNumber : ''}&startDate=${
        form.startDate > 0 ? moment(form.startDate).format('MM-DD-YYYY') : ''
      }&endDate=${form.endDate > 0 ? moment(form.endDate).format('MM-DD-YYYY') : ''}${state.length > 0 ? '&state=' + state : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}&expenseId=${expenseList}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doAddQueryRequest() {
  yield delay(500);
  const form: ReportInterface = yield select(selectForm);
  const name: string = yield select(selectName);
  const expenseList: string[] = yield select(selectSelectExpenseType);
  const searchText: string = yield select(SelectSearch);
  const employeeId: string = yield select(selectEmployeeId);
  const reportNumber: string = yield select(selectReportNumber);
  const state: string = yield select(selectState);
  if (name.length === 0) {
    toast.error('Please fill Query Name');
  }
   const data ={
    expenseId : expenseList,
    state :state,
    endDate : form.endDate > 0 ? moment(form.endDate).format('MM-DD-YYYY') : '',
    startDate : form.startDate > 0 ? moment(form.startDate).format('MM-DD-YYYY') : '' ,
    reportNumber : reportNumber,
    employeeID : employeeId,
    search :searchText,
    name: name
  }
  try {
    const response: AxiosResponse = yield call(doAddQuery, data);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setName(''));
    yield put(actions.doGetQuery());
    toast.success(response.data.message);
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetQueryListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetQueryList);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setQueryListData(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* ReportRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doAddQuery, doAddQueryRequest);
  yield takeLatest(actions.doGetQuery, doGetQueryListRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetListOrganization, doGetListOrganizationRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doGetDepartmentList, doGetDepartmentListRequest);
  yield takeLatest(actions.doGetSubDepartmentList, doGetSubDepartmentListRequest);
  yield takeLatest(actions.doGetAvailableExpensesList, doGetAvailableExpensesListRequest);
  yield takeLatest(actions.doGetAvailableExpensesEdit, doGetAvailableExpensesListDataRequest);
  yield takeLatest(actions.doGetDepartmentData, doGetDepartmentDataRequest);
  yield takeLatest(actions.doGetAvailableExpensesListWithoutFilter, doGetAvailableExpensesListWithoutFilterRequest);
  yield takeLatest(actions.doGetApproverReportList, doGetApproverReportListRequest);
  yield takeLatest(actions.doApproveReports, doApproveReportRequest);
  yield takeLatest(actions.doGetDownloadsList, doGetDownloadsRequest);
  yield takeLatest(actions.doGetReportCountData, doGetReportCountDataRequest);
  yield takeLatest(actions.doCopyReports, doCopyReportRequest);
}

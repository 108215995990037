// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  CalendarOutlined,
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  KeyOutlined,
  DollarOutlined,
  AlignLeftOutlined,
  BookOutlined,
  ProfileOutlined,
  PieChartOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  TransactionOutlined,
} from '@ant-design/icons';
// type
import { NavItemType } from '../types/menu';
import React from 'react';

// icons
const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  KeyOutlined,
  DollarOutlined,
  AlignLeftOutlined,
  BookOutlined,
  ProfileOutlined,
  PieChartOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  TransactionOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const custom: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'managereport',
      title: <FormattedMessage id="Report" />,
      type: 'collapse',
      icon: icons.BookOutlined,
      children: [
        {
          id: 'managereportlist',
          title: <FormattedMessage id="Manage Report" />,
          type: 'item',
          url: '/managereport/list'
        }
      ]
    },
  ]
};

export default custom;

export interface GradeInterface {
  id: string;
  _id: string;
  title: string;
  isActive: IsActivityEnum;
}
export enum IsActivityEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export interface GradeListAllInterface {
  _id: string;
  title: string;
}

export interface GradeState {
  list: GradeInterface[];
  form: GradeInterface;
  gradeAll: GradeListAllInterface[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  currentErrorField: number[];
}

export type InitialGradeState = GradeState;

import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { OrganizationInterface } from '../types';
import { selectForm, SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import {
  doAddOrganization,
  doGetOrganizationList,
  doGetOrganizationEdit,
  doUpdateOrganization,
  doDeleteOrganization,
  doGetOrganizationAnalyticsList
} from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { toast } from 'react-toastify';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: OrganizationInterface = yield select(selectForm);
  if (
    form.name?.length === 0 ||
    form.email?.length === 0 ||
    form.address?.length === 0 ||
    form.panNumber?.length === 0 ||
    form.gstNumber?.length === 0 ||
    form.mobileNumber?.length === 0
  ) {
    if (form.name?.length === 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please fill organization name');
    }
    if (form.email?.length === 0) {
      yield put(actions.setCurrentErrorField(2));
      toast.error('Please fill email');
    }
    if (form.address?.length === 0) {
      yield put(actions.setCurrentErrorField(3));
      toast.error('Please fill organization address');
    }
    if (form.panNumber?.length === 0) {
      yield put(actions.setCurrentErrorField(4));
      toast.error('Please fill organization PAN Number');
    }
    if (form.gstNumber?.length === 0) {
      yield put(actions.setCurrentErrorField(5));
      toast.error('Please fill organization GST Number');
    }
    if (form.mobileNumber?.length === 0) {
      yield put(actions.setCurrentErrorField(6));
      toast.error('Please fill organization mobile number');
    }
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddOrganization, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const response: AxiosResponse = yield call(
      doGetOrganizationList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetOrganizationEdit, action.payload);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: OrganizationInterface = yield select(selectForm);

  if (
    form.name.length === 0 ||
    form.address?.length === 0 ||
    form.panNumber.length === 0 ||
    form.gstNumber.length === 0 ||
    form.mobileNumber.length === 0
  ) {
    if (form.name.length === 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please fill organization name');
    }
    if (form.address.length === 0) {
      yield put(actions.setCurrentErrorField(3));
      toast.error('Please fill organization address');
    }
    if (form.panNumber.length === 0) {
      yield put(actions.setCurrentErrorField(4));
      toast.error('Please fill organization PAN Number');
    }
    if (form.gstNumber.length === 0) {
      yield put(actions.setCurrentErrorField(5));
      toast.error('Please fill organization GST Number');
    }
    if (form.mobileNumber.length === 0) {
      yield put(actions.setCurrentErrorField(6));
      toast.error('Please fill organization mobile number');
    }
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateOrganization, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doDeleteRequest(action: { payload: string }) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doDeleteOrganization, action.payload);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);

    yield put(actions.doGetList());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetAnalyticListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const response: AxiosResponse = yield call(
      doGetOrganizationAnalyticsList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setAnalyticsList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* OrganizationRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doDelete, doDeleteRequest);
  yield takeLatest(actions.doGetAnalyticsList, doGetAnalyticListRequest);

}

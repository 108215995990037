export interface DesignationInterface {
  id: string;
  _id:string;
  isActive: IsActivityEnum;
  role: ENUM_ROLE_TYPE;
  designation: string;
}

export enum ENUM_ROLE_TYPE {
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  ORGANIZATION_MANAGER = 'ORGANIZATION_MANAGER',
  EMPLOYEE= 'EMPLOYEE',
}
export enum IsActivityEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export interface DesignationState {
  list: DesignationInterface[];
  form: DesignationInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  currentErrorField: number[];
}

export type InitialDesignationState = DesignationState;

import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { EmployeeInterface } from '../types';
import { selectForm, SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import {
  doAddEmployee,
  doGetEmployeeList,
  doGetEmployeeEdit,
  doUpdateEmployee,
  doGetDesignationListAll,
  doGetAllLevel,
  doGetApproverList
} from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { toast } from 'react-toastify';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: EmployeeInterface = yield select(selectForm);
  let check = false;
  for (let index = 0; index < form.levelApprover.length; index++) {
    const element = form.levelApprover[index];
    if (element.approverId != '') {
      check = true;
    }
  }
  const data = {
    id: form.id,
    _id: form._id,
    firstName: form.firstName,
    middleName: form.middleName,
    lastName: form.lastName,
    fullName: form.fullName,
    dob: form.dob,
    dateOfJoining: form.dateOfJoining,
    email: form.email,
    mobileNumber: form.mobileNumber,
    address: form.address,
    location: form.location,
    region: form.region,
    role: form.role,
    designation: form.designation,
    designationName: form.designationName,
    isActive: form.isActive,
    gender: form.gender,
    zonal: form.zonal,
    band: form.band,
    level: form.level,
    approver: form.approver,
    levelApprover: check == false ? [] : form.levelApprover,
    levelApproverForm: {
      levelId: '',
      title: '',
      name: '',
      index: 0
    },
    department: form.department,
    subDepartment: form.subDepartment,
    grade: form.grade,
    gradeId: form.gradeId,
    employeeID: form.employeeID,
    terminationDate: form.terminationDate,
    costCenter: form.costCenter
  };

  if (
    form.firstName.length == 0 ||
    form.lastName.length == 0 ||
    form.dob.length == 0 ||
    form.email.length == 0 ||
    form.employeeID.length == 0 ||
    form.mobileNumber.length == 0 ||
    form.mobileNumber.length > 10 ||
    form.mobileNumber.length < 10 ||
    form.address.length == 0 ||
    form.dateOfJoining.length == 0 ||
    form.designation.length == 0 ||
    form.department.length == 0 ||
    form.location.length == 0 ||
    form.region.length == 0 ||
    form.costCenter.length === 0 ||
    form.gradeId.length == 0
  ) {
    if (form.firstName.length == 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please fill first name');
    }
    if (form.lastName.length === 0) {
      yield put(actions.setCurrentErrorField(2));
      toast.error('Please fill last name');
    }
    if (form.dob.length == 0) {
      yield put(actions.setCurrentErrorField(3));
      toast.error('Please select date of birth');
    }
    if (form.email.length === 0) {
      yield put(actions.setCurrentErrorField(4));
      toast.error('Please fill email');
    }
    if (form.email.length > 0) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
        yield put(actions.setCurrentErrorField(4));
        toast.error('Please enter valid email');
      }
    }
    if (form.employeeID.length == 0) {
      yield put(actions.setCurrentErrorField(5));
      toast.error('Please fill employee id');
    }
    if (form.mobileNumber.length === 0) {
      yield put(actions.setCurrentErrorField(6));
      toast.error('Please fill mobile no.');
    }
    if (form.mobileNumber.length > 10) {
      yield put(actions.setCurrentErrorField(6));
      toast.error('Please fill valid mobile no.');
    }
    if (form.mobileNumber.length < 10) {
      yield put(actions.setCurrentErrorField(6));
      toast.error('Please fill valid mobile no.');
    }
    if (form.address.length == 0) {
      yield put(actions.setCurrentErrorField(7));
      toast.error('Please fill address');
    }
    if (form.dateOfJoining.length === 0) {
      yield put(actions.setCurrentErrorField(8));
      toast.error('Please select date of joining');
    }
    if (form.designation.length === 0) {
      yield put(actions.setCurrentErrorField(9));
      toast.error('Please fill designation');
    }
    if (form.department.length === 0) {
      yield put(actions.setCurrentErrorField(10));
      toast.error('Please select department');
    }
    if (form.location.length === 0) {
      yield put(actions.setCurrentErrorField(12));
      toast.error('Please fill location');
    }
    if (form.region.length === 0) {
      yield put(actions.setCurrentErrorField(13));
      toast.error('Please fill region');
    }
    if (form.costCenter.length === 0) {
      yield put(actions.setCurrentErrorField(14));
      toast.error('Please select cost center');
    }
    if (form.gradeId.length === 0) {
      yield put(actions.setCurrentErrorField(15));
      toast.error('Please select grade');
    }
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddEmployee, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const response: AxiosResponse = yield call(
      doGetEmployeeList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetEmployeeEdit, action.payload);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: EmployeeInterface = yield select(selectForm);

  if (
    form.firstName.length == 0 ||
    form.lastName.length == 0 ||
    form.dob.length == 0 ||
    form.employeeID.length == 0 ||
    form.mobileNumber.length == 0 ||
    form.mobileNumber.length > 10 ||
    form.mobileNumber.length < 10 ||
    form.address.length == 0 ||
    form.dateOfJoining.length == 0 ||
    form.designation.length == 0 ||
    form.department.length == 0 ||
    form.location.length == 0 ||
    form.region.length == 0 ||
    form.costCenter.length === 0 ||
    form.gradeId.length == 0
  ) {
    if (form.firstName.length == 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please fill first name');
    }
    if (form.lastName.length === 0) {
      yield put(actions.setCurrentErrorField(2));
      toast.error('Please fill last name');
    }
    if (form.dob.length == 0) {
      yield put(actions.setCurrentErrorField(3));
      toast.error('Please select date of birth');
    }
    if (form.employeeID.length == 0) {
      yield put(actions.setCurrentErrorField(5));
      toast.error('Please fill employee id');
    }
    if (form.mobileNumber.length === 0) {
      yield put(actions.setCurrentErrorField(6));
      toast.error('Please fill mobile no.');
    }
    if (form.mobileNumber.length > 10) {
      yield put(actions.setCurrentErrorField(6));
      toast.error('Please fill valid mobile no.');
    }
    if (form.mobileNumber.length < 10) {
      yield put(actions.setCurrentErrorField(6));
      toast.error('Please fill valid mobile no.');
    }
    if (form.address.length == 0) {
      yield put(actions.setCurrentErrorField(7));
      toast.error('Please fill address');
    }
    if (form.dateOfJoining.length === 0) {
      yield put(actions.setCurrentErrorField(8));
      toast.error('Please select date of joining');
    }
    if (form.designation.length === 0) {
      yield put(actions.setCurrentErrorField(9));
      toast.error('Please fill designation');
    }
    if (form.department.length === 0) {
      yield put(actions.setCurrentErrorField(10));
      toast.error('Please select department');
    }
    if (form.location.length === 0) {
      yield put(actions.setCurrentErrorField(12));
      toast.error('Please fill location');
    }
    if (form.region.length === 0) {
      yield put(actions.setCurrentErrorField(13));
      toast.error('Please fill region');
    }
    if (form.costCenter.length === 0) {
      yield put(actions.setCurrentErrorField(14));
      toast.error('Please select cost center');
    }
    if (form.gradeId.length === 0) {
      yield put(actions.setCurrentErrorField(15));
      toast.error('Please select grade');
    }
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateEmployee, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    if (error?.response?.data?.message == 'Please select approver for all levels') {
      yield put(actions.setCurrentErrorField(16));
    }
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetDesignationListRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetDesignationListAll, `?role=${action.payload}`);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setDesignationList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetAllLevelRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetAllLevel);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setAllLevelList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetApproverListRequest(action: { payload: { id: string } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetApproverList, `?id=${action.payload.id}`);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setApproverList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doAddLevelRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* EmployeeRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doGetDesignationList, doGetDesignationListRequest);
  yield takeLatest(actions.doGetAllLevelList, doGetAllLevelRequest);
  yield takeLatest(actions.doGetApproverList, doGetApproverListRequest);
}

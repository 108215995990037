import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from 'newTypes/RootState';

const selectDomain = (state: RootState) => {
  if (state) {
    return state.AddRoletate;
  } else {
    return initialState;
  }
};

export const SelectUpdatePasswordFormData = createSelector([selectDomain], (state) => state.updatePasswordFormData);
export const SelectUpdatePasswordList = createSelector([selectDomain], (state) => state.updatePasswordList);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectEditProfileFormData = createSelector([selectDomain], (state) => state.editProfileForm);
export const SelectEditProfileList = createSelector([selectDomain], (state) => state.editProfileList);


export interface loginFormData {
  username: string;
  password: string;
  errors: Array<string>;
  success: string;
}
export interface UpdatePasswordInterface {
  id: string;
  password: string;
  confirmPassword: string;
}
export interface ForgetPasswordInterface {
  email: string;
  id: string;
}
export interface ResetPasswordInterface {
  id: string;
  password: string;
  confirmPassword: string;
}
export interface CodeVerificationInterface {
  hash: string;
  otp: string;
}

export interface AuthState {
  loginForm: {
    username: string;
    password: string;
    errors: Array<string>;
    success: string;
  };
  userData: {
    name: string;
    email: string;
    usertype: ENUM_ROLE_TYPE;
    profilePic: string;
    importDefaultStatus: boolean;
    costCenter: string;
    costCenterName: string;
  };
  updatePasswordFormData: UpdatePasswordInterface;
  forgetPasswordFormData: ForgetPasswordInterface;
  resetPasswordFormData: ResetPasswordInterface;
  codeVerificationForm: CodeVerificationInterface;
  userEmail: string;
  loading: boolean;
  buttonLoading: boolean;
  token: string;
  isLogin: boolean;
  resetEmailAddress: String;
}
export enum RolesEnum {
  SUPERADMIN = 1,
  ADMIN = 2,
  MANAGER = 3,
  USER = 4
}
export enum ENUM_ROLE_TYPE {
  ALL = 'ALL',
  ADMIN = 'ADMIN',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  ORGANIZATION_MANAGER = 'ORGANIZATION_MANAGER',
  EMPLOYEE = 'EMPLOYEE'
}

export enum ClinicErrorType {
  USERNAME_EMPTY = 10,
  PASSWORD_EMPTY = 20,
  USER_NOT_FOUND = 404,
  SUCCESS = 200,
  UNAUTHORIZED = 400,
  ERROR = 500,
  NO_PERMISSIONS = 401
}

export type InitialAuthState = AuthState;

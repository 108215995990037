import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { MyClaimInterface } from '../types';
import {
  selectForm,
  SelectPageNo,
  SelectPageSize,
  SelectSearch,
  SelectSortColumn,
  SelectSortDir,
  selectState,
  selectEmployeeId,
  selectReportNumber,
  selectRoleState
} from './selector';
import { doGetMyApprovedReportList, doGetMyReportList, doGetReportEdit, doGetTopSpenderList } from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ENUM_ROLE_TYPE } from 'sections/auth/types';

export function* doGetListRequest() {
  yield delay(500);
  try {
    const form: MyClaimInterface = yield select(selectForm);
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const employeeId: string = yield select(selectEmployeeId);
    const reportNumber: string = yield select(selectReportNumber);
    const state: string = yield select(selectState);
    const response: AxiosResponse = yield call(
      doGetMyReportList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        employeeId.length > 0 ? '&employeeID=' + employeeId : ''
      }${reportNumber.length > 0 ? '&reportNumber=' + reportNumber : ''}&startDate=${
        form.startDate > 0 ? moment(form.startDate).format('MM-DD-YYYY') : ''
      }&endDate=${form.endDate > 0 ? moment(form.endDate).format('MM-DD-YYYY') : ''}${state.length > 0 ? '&state=' + state : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetReportEdit, action.payload);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetListMakeForPaymentRequest() {
  yield delay(500);

  try {
    const form: MyClaimInterface = yield select(selectForm);
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const reportNumber: string = yield select(selectReportNumber);
    const response: AxiosResponse = yield call(
      doGetMyApprovedReportList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        reportNumber.length > 0 ? '&reportNumber=' + reportNumber : ''
      }&startDate=${form.startDate > 0 ? moment(form.startDate).format('MM-DD-YYYY') : ''}&endDate=${
        form.endDate > 0 ? moment(form.endDate).format('MM-DD-YYYY') : ''
      }${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setSentForPaymentList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetTopSpenderListRequest() {
  yield delay(500);
  try {
    const form: MyClaimInterface = yield select(selectForm);
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const reportNumber: string = yield select(selectReportNumber);
    const roleState: ENUM_ROLE_TYPE = yield select(selectRoleState);
    const response: AxiosResponse = yield call(
      doGetTopSpenderList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${reportNumber.length > 0 ? '&employeeID=' + reportNumber : ''}&startDate=${
        form.startDate > 0 ? moment(form.startDate).format('MM-DD-YYYY') : ''
      }&endDate=${form.endDate > 0 ? moment(form.endDate).format('MM-DD-YYYY') : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}&role=${roleState}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setTopSpenderList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* MyClaimRepoSaga() {
  yield takeLatest(actions.doGetTopSpenderList, doGetTopSpenderListRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  // yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doGetListMakeForPayment, doGetListMakeForPaymentRequest);
}

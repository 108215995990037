import { PayloadAction } from '@reduxjs/toolkit';
import { IsActivityEnum, OrganizationInterface, OrganizationState } from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: OrganizationState = {
  loading: false,
  form: {
    id: '',
    _id: '',
    email: '',
    name: '',
    isActive: IsActivityEnum.ACTIVE,
    address: '',
    panNumber: '',
    gstNumber: '',
    mobileNumber: '',
    createdAt: '',
    updatedAt: '',
    employeeCount: 0,
    reportCount: 0
  },
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: '',
  currentErrorField: [],
  AnalyticsList:[]
};

export const useClinicSlice = createSlice({
  name: 'organizationState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (!state.currentErrorField.includes(action.payload)) {
        state.currentErrorField.push(action.payload);
      }
    },
    removeCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (state.currentErrorField.includes(action.payload)) {
        let index = state.currentErrorField.indexOf(action.payload);
        state.currentErrorField.splice(index, 1);
      }
    },
    clearCurrentErrorField: (state) => {
      state.currentErrorField = [];
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    clearForm: (state) => {
      state.form.id = '';
      state.form._id = '';
      state.form.email = '';
      state.form.address = '';
      state.form.panNumber = '';
      state.form.gstNumber = '';
      state.form.mobileNumber = '';
      state.form.isActive = IsActivityEnum.ACTIVE;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetAnalyticsList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doDelete: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setList: (state, action: PayloadAction<Array<OrganizationInterface>>) => {
      state.list = action.payload;
    },
    setAnalyticsList: (state, action: PayloadAction<Array<OrganizationInterface>>) => {
      state.AnalyticsList = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<OrganizationInterface>) => {
      state.form = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

import { PayloadAction } from '@reduxjs/toolkit';
import { IsActivityEnum, ExpenseLevelInterface, ExpenseLevelState, ExpenseLeveAll } from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: ExpenseLevelState = {
  loading: false,
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  form: {
    id: '',
    _id: '',
    title: '',
    level: '',
    levelId: '',
    minPrice: 0,
    maxPrice: 0,
    levelDataForm: {
      id: '',
      _id: '',
      level: '',
      levelId: '',
      minPrice: 0,
      maxPrice: 0,
      action: ''
    },
    levelDataList: [],
    levelIsDeleteList: [],
    isActive: IsActivityEnum.ACTIVE
  },
  sortColumn: '',
  sortDir: '',
  listAll: [],
  currentErrorField: []
};

export const useClinicSlice = createSlice({
  name: 'expenseLevelState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (!state.currentErrorField.includes(action.payload)) {
        state.currentErrorField.push(action.payload);
      }
    },
    removeCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (state.currentErrorField.includes(action.payload)) {
        let index = state.currentErrorField.indexOf(action.payload);
        state.currentErrorField.splice(index, 1);
      }
    },
    clearCurrentErrorField: (state) => {
      state.currentErrorField = [];
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearForm: (state) => {
      state.form.id = '';
      state.form._id = '';
      state.form.title = '';
      state.form.level = '';
      state.form.levelId = '';
      state.form.levelDataList = [];
      state.form.isActive = IsActivityEnum.ACTIVE;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setList: (state, action: PayloadAction<Array<ExpenseLevelInterface>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<ExpenseLevelInterface>) => {
      state.form._id = action.payload._id;
      state.form.isActive = action.payload.isActive;
      state.form.level = action.payload.level;
      state.form.levelDataList = action.payload.levelDataList;
      state.form.title = action.payload.title;
      state.form.id = '';
      state.form.levelId = '';
      state.form.minPrice = 0;
      state.form.maxPrice = 0;
      state.form.levelDataForm.level = '';
      state.form.levelDataForm.levelId = '';
      state.form.levelDataForm.maxPrice = 0;
      state.form.levelDataForm.minPrice = 0;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },

    updateLevelFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.levelDataForm.${action.payload.key}`, action.payload.value);
    },
    doAddLevel: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.form.levelDataList.push(state.form.levelDataForm);
      state.form.levelIsDeleteList.push(state.form.levelDataForm);
    },
    doDeleteLevel: (state, action: PayloadAction<number>) => {
      if (state.form.levelDataList[action.payload]._id?.length > 0) {
        state.form.levelDataList[action.payload].action = 'delete';
      } else {
        state.form.levelDataList.splice(action.payload, 1);
      }
    },
    doDeleteLevel1: (state, action: PayloadAction<number>) => {
      state.form.levelIsDeleteList.splice(action.payload, 1);
    },
    clearLevelForm: (state) => {
      state.form.levelDataForm.level = '';
      state.form.levelDataForm.levelId = '';
      state.form.levelDataForm.minPrice = 0;
      state.form.levelDataForm.maxPrice = 0;
    },
    doGetListAll: (state) => {},
    doGetListAllSecnd: (state) => {},
    setListAll: (state, action: PayloadAction<Array<ExpenseLeveAll>>) => {
      state.listAll = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

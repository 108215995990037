import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { EditProfileRequest, UpdateProfileRequest, doGetProfilePicRequest, doUplodeProfilePicRequest } from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { SelectEditProfileFormData } from './selector';
import { UpdateProfileInterface } from '../types';
import { toast } from 'react-toastify';

export function* doEditProfileRequest() {
  yield delay(500);
  if (localStorage.getItem('token')) {
    try {
      const response: AxiosResponse = yield call(EditProfileRequest);
      if (response && !response.status) {
        toast.error(response.data.message);
        return;
      }

      yield put(
        actions.setDataForEdit({
          name: response.data.data.name,
          email: response.data.data.email,
          address: response.data.data.address,
          mobileNumber: response.data.data.mobileNumber,
          profilePic: response.data.data.profilePic
        })
      );
    } catch (error: any) {
      CatchBlockFunction(error);
    }
  }
}
export function* doUpdateProfileRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: UpdateProfileInterface = yield select(SelectEditProfileFormData);
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(UpdateProfileRequest, form);
    yield put(actions.setLoading(false));
    if (!response.data.statusCode) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doFileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetProfilePicRequest, action.payload.data);
    if (!response.status) {
      toast.error(response.data.message);
      return;
    }
    yield call(action?.payload?.callback(response.data.signedUrl, response.data.fileNameWithPrefix));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUploadRequest(action: { payload: { data: any; signedUrl: string; result: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      doUplodeProfilePicRequest,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    if (!response.status) {
      toast.error(response.data.message);
      return;
    } else {
      toast.success(response.data.message);
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* authRepoSagaRoleUser() {
  yield takeLatest(actions.doGetEditProfile, doEditProfileRequest);
  yield takeLatest(actions.doUpdateEditProfile, doUpdateProfileRequest);
  yield takeLatest(actions.doAddImage, doFileUploadRequest);
  yield takeLatest(actions.doUploadeImage, doUploadRequest);
}

import { PayloadAction } from '@reduxjs/toolkit';
import {
  IsActivityEnum,
  ENUM_ROLE_TYPE,
  EmployeeInterface,
  DesignationtInterface,
  EmployeeState,
  GenderEnum,
  AllLevelInterface,
  ApproverListInterface
} from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';
export const initialState: EmployeeState = {
  loading: false,
  form: {
    id: '',
    _id: '',
    email: '',
    isActive: IsActivityEnum.ACTIVE,
    mobileNumber: '',
    address: '',
    role: ENUM_ROLE_TYPE.EMPLOYEE,
    designation: '',
    designationName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    fullName: '',
    dob: '',
    dateOfJoining: '',
    location: '',
    region: '',
    gender: GenderEnum.MALE,
    zonal: '',
    band: '',
    level: '',
    approver: '',
    levelApprover: [],
    levelApproverForm: {
      levelId: '',
      approverId: '',
      title: '',
      name: '',
      index: 0
    },
    department: '',
    subDepartment: '',
    grade: '',
    gradeId: '',
    employeeID: '',
    terminationDate: '',
    costCenter: ''
  },
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: '',
  designationlist: [],
  allLevelList: [],
  approverlist: [],
  currentErrorField: []
};

export const useClinicSlice = createSlice({
  name: 'employeeState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setAllLevelList: (state, action: PayloadAction<Array<AllLevelInterface>>) => {
      state.allLevelList = action.payload;
      if (action.payload.length > state.form.levelApprover.length) {
        for (let index = 0; index < action.payload.length; index++) {
          const element = action.payload[index];
          state.form.levelApprover.push({
            approverId: '',
            name: '',
            index: state.form.levelApprover.length + 1,
            levelId: element._id,
            title: element.title
          });
        }
      }
    },
    updateLevelApproverFormValue: (state, action: PayloadAction<{ key: string; value: any; index: number }>) => {
      if (state.form.levelApprover.length > 0) {
        state.form.levelApprover[action.payload.index].approverId = action.payload.value;
      } else {
        for (let index = 0; index < state.allLevelList.length; index++) {
          const element = state.allLevelList[index];
          if (action.payload.index == index) {
            state.form.levelApprover.push({
              approverId: action.payload.value,
              name: '',
              index: 0,
              levelId: element._id,
              title: element.title
            });
          } else {
            state.form.levelApprover.push({ approverId: '', name: '', index: 0, levelId: element._id, title: element.title });
          }
        }
      }
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (!state.currentErrorField.includes(action.payload)) {
        state.currentErrorField.push(action.payload);
      }
    },
    removeCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (state.currentErrorField.includes(action.payload)) {
        let index = state.currentErrorField.indexOf(action.payload);
        state.currentErrorField.splice(index, 1);
      }
    },
    clearCurrentErrorField: (state) => {
      state.currentErrorField = [];
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    clearForm: (state) => {
      state.form.id = '';
      state.form._id = '';
      state.form.email = '';
      state.form.isActive = IsActivityEnum.ACTIVE;
      state.form.mobileNumber = '';
      state.form.address = '';
      state.form.role = ENUM_ROLE_TYPE.EMPLOYEE;
      state.form.designation = '';
      state.form.designationName = '';
      state.form.firstName = '';
      state.form.middleName = '';
      state.form.lastName = '';
      state.form.fullName = '';
      state.form.dob = '';
      state.form.dateOfJoining = '';
      state.form.location = '';
      state.form.region = '';
      state.form.gender = GenderEnum.MALE;
      state.form.zonal = '';
      state.form.band = '';
      state.form.levelApprover = [];
      state.form.level = '';
      state.form.approver = '';
      state.form.department = '';
      state.form.subDepartment = '';
      state.form.grade = '';
      state.form.gradeId = '';
      state.form.employeeID = '';
      state.form.costCenter = '';
      state.form.terminationDate = '';
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setList: (state, action: PayloadAction<Array<EmployeeInterface>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<EmployeeInterface>) => {
      state.form = action.payload;
    },
    doGetDesignationList: (state, action: PayloadAction<string>) => {},
    setDesignationList: (state, action: PayloadAction<Array<DesignationtInterface>>) => {
      state.designationlist = action.payload;
    },
    doGetAllLevelList: (state) => {},
    doGetApproverList: (state, action: PayloadAction<{ id: any }>) => {},
    setApproverList: (state, action: PayloadAction<Array<ApproverListInterface>>) => {
      state.approverlist = action.payload;
    },
    doAddLevelApprover: (state) => {
      state.form.levelApprover?.push(state.form.levelApproverForm);
    },
    clearLevelApproverFrom: (state) => {
      state.form.levelApproverForm.levelId = '';
      state.form.levelApproverForm.approverId = '';
      state.form.levelApproverForm.title = '';
      state.form.levelApproverForm.name = '';
    },
    doDeleteLevelApprover: (state, action: PayloadAction<number>) => {
      state.form.levelApprover.splice(action.payload, 1);
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

import { PayloadAction } from '@reduxjs/toolkit';
import { IsActivityEnum, IsMandatoryEnum, LevelInterface, LevelState } from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: LevelState = {
  loading: false,
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  form: {
    id: '',
    _id: '',
    title: '',
    isActive: IsActivityEnum.ACTIVE,
    order: 0,
    isMandatory: IsMandatoryEnum.NO
  },
  sortColumn: '',
  sortDir: '',
  currentErrorField: [],
};

export const useClinicSlice = createSlice({
  name: 'levelState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (!state.currentErrorField.includes(action.payload)) {
        state.currentErrorField.push(action.payload);
      }
    },
    removeCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (state.currentErrorField.includes(action.payload)) {
        let index = state.currentErrorField.indexOf(action.payload);
        state.currentErrorField.splice(index, 1);
      }
    },
    clearCurrentErrorField: (state) => {
      state.currentErrorField = [];
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearForm: (state) => {
      state.form.title = '';
      state.form.id = '';
      state.form._id = '';
      state.form.order = 0;
      state.form.isActive = IsActivityEnum.ACTIVE;
      state.form.isMandatory = IsMandatoryEnum.NO;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setList: (state, action: PayloadAction<Array<LevelInterface>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<LevelInterface>) => {
      state.form = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

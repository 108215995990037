export interface ReportInterface {
  _id :string;
  reportNumber: string;
  name: string;
  employeeID: string;
  employeeName: string;
  email: string;
  dob: string;
  mobileNumber: string;
  address: string;
  location: string;
  region: string;
  zonal: string;
  band: string;
  grade: string;
  country: CountryEnum;
  designation: string;
  department: string;
  subDepartment: string;
  dateOfJoining: string;
  terminationDate?: string; // Optional, as it may be empty
  costCenter: string;
  gender: string;
  amount: number;
  date: string;
  businessPurpose: string;
  amountAdjusted: number;
  foreignCurrencyAmount: number;
  currency: CurrencyEnum;
  comment: string;
  expenseTotalAmount: number;
  amountInserted: number;
  statusContent: string;
  expense: string;
  transactionDate: string;
  vendorName: string;
  expenseLocation: string;
  paymentTypeName: string;
  expenseCurrency: string;
  travelReason: string;
  expenseComment: string;
  noReceiptReason: string;

  state: ReportStateEnum;
  startDate: any;
  endDate: any;
  costCenterName: string;
  subDepartmentName: string;
  departmentName: string;
}
export interface DepartmentInterface {
  _id: string;
  department: string;
}
export interface SubDepartmentInterface {
  _id: string;
  subDepartment: string;
}
export interface AvailableExpenseInterface {
  _id: string;
  title: string;
  receiptRequired: ReceiptEnum;
  validityDays: number;
  expenseSearch: string;
}
export interface DepartmentDataInterface {
  _id: string;
  department: string;
  departmentName: string;
  subDepartment: string;
  subDepartmentName: string;
}
export interface ReportCountDataInterface {
  manageReport: number;
  myClaim: number;
  myClaimLibrary: number;
}

export enum CurrencyEnum {
  INR = 'INR',
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  YEN = 'YEN'
}
export enum CountryEnum {
  INDIA = 0,
  UNITED_STATE = 1,
  CANADA = 2,
  EUROPE = 3,
  CHINA = 4
}

export enum ReportStateEnum {
  DRAFT = 1,
  SUBMIT = 2,
  INPROGRESS = 3,
  APPROVED = 4,
  RECALL = 5,
  REJECT = 6,
  CLAIM = 7,
  SENT_FOR_PAYMENT = 8
}
export enum ReceiptEnum {
  NO = 0,
  YES = 1
}
export enum ReportStateFilterEnum {
  All = 0,
  DRAFT = 1,
  SUBMIT_AND_PENDING_APPROVAL = 2,
  APPROVED_AND_IN_ACCOUNTING_REVIEW = 4,
  APPROVED = 7,
  SENT_FOR_PAYMENT = 8
}
export interface ReportState {
  list: ReportInterface[];
  form: ReportInterface;
  departmentList: DepartmentInterface[];
  subDepartmentList: SubDepartmentInterface[];
  departmentForm: DepartmentInterface;
  availabeExpenseList: AvailableExpenseInterface[];
  availabeExpenseForm: AvailableExpenseInterface;
  departmentData: DepartmentDataInterface;
  approverList: ReportInterface[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  employeeId: string;
  reportNumber: string;
  state: ReportStateFilterEnum;
  expenseLoading: boolean;
  idArray: string[];
  downLoadList: [];
  currentErrorField: number[];
  reportCountData: ReportCountDataInterface;
  selectExpenseType :string[];
  name:string;
  QueryListData :QueryListDataInterface[]
}

export interface QueryListDataInterface {
  expenseId: string[];
  state: number;
  endDate: string;
  startDate: string;
  reportNumber: any;
  employeeID: string;
  search: string;
  name: string;
}

export type InitialReportState = ReportState;

import { GenderEnum } from 'pages/employee/types';

export interface ExcelDataInterface {
  id: string;
  _id: string;
  fileName: string;
  error: string;
  status: EmployeeDataFileStatusEnum;
  organizationId: string;
  createdAt: string;
  organization: string;
}
export interface ExcelDetailsDataInterface {
  id: string;
  _id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  error: ErrorInterface[];
  dob: string;
  email: string;
  mobileNumber: string;
  address: string;
  location: string;
  region: string;
  zonal: string;
  band: string;
  designation: string;
  department: string;
  subDepartment: string;
  role: string;
  dateOfJoining: string;
  terminationDate: string;
  gender: GenderEnum;
  isActive: IsActivityEnum;
  status: EmployeeDataContentStatusEnum;
}
export enum EmployeeFileContentStatusEnum {
  ALL = 0,
  PROCESSED = 1,
  PENDING = 2,
  FAILED = 3,
  PROCESSED_WITH_ERRORS = 4,
}
export enum IsActivityEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export enum EmployeeDataFileStatusEnum {
  PROCESSED = 1,
  PENDING = 2,
  PARTIALLY_PROCESSED = 3,
  PROCESSED_WITH_ERRORS = 4,
  PROCESSING = 5,
  FAILED = 6
}
export enum EmployeeDataContentStatusEnum {
  PROCESSED = 1,
  PENDING = 2,
  FAILED = 3,
  PROCESSED_WITH_ERROR = 4
}
export interface ErrorInterface {
  field: string;
  message: string;
}
export interface ExportToExcelState {
  list: ExcelDataInterface[];
  detailsList: ExcelDetailsDataInterface[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  pageNoData: number;
  pageSizeData: number;
  totalRowData: number;
  sortColumnData: string;
  sortDirData: string;
  filterStatus: EmployeeFileContentStatusEnum;
}

export type InitialExportToExcelState = ExportToExcelState;

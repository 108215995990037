export interface ExpenseLevelInterface {
  id: string;
  _id: string;
  title: string;
  level: string;
  levelId: string;
  minPrice: number;
  maxPrice: number;
  levelDataForm: levelDataForm;
  levelDataList: levelDataForm[];
  levelIsDeleteList: levelDataForm[];
  isActive: IsActivityEnum;
}
export enum IsActivityEnum {
  INACTIVE = 0,
  ACTIVE = 1
}

export interface levelDataForm {
  id: string;
  _id: string;
  level: string;
  levelId: string;
  minPrice: number;
  maxPrice: number;
  action: string;
}
export interface ExpenseLeveAll {
  _id: string;
  title: string;
}

export interface ExpenseLevelState {
  list: ExpenseLevelInterface[];
  form: ExpenseLevelInterface;
  listAll: ExpenseLeveAll[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  currentErrorField: number[];
}

export type InitialExpenseLevelState = ExpenseLevelState;

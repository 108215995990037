import { PayloadAction } from '@reduxjs/toolkit';
import {
  ReportInterface,
  ReportState,
  CurrencyEnum,
  SubDepartmentInterface,
  DepartmentInterface,
  AvailableExpenseInterface,
  ReceiptEnum,
  DepartmentDataInterface,
  ReportStateFilterEnum,
  CountryEnum,
  ReportCountDataInterface,
  QueryListDataInterface,
  ReportStateEnum
} from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: ReportState = {
  loading: false,
  form: {
    _id: '',
    reportNumber: '',
    name: '',
    employeeID: '',
    employeeName: '',
    email: '',
    dob: '',
    mobileNumber: '',
    address: '',
    location: '',
    region: '',
    zonal: '',
    band: '',
    grade: '',
    country: CountryEnum.INDIA,
    designation: '',
    department: '',
    subDepartment: '',
    dateOfJoining: '',
    costCenter: '',
    gender: '',
    amount: 0,
    date: '',
    businessPurpose: '',
    amountAdjusted: 0,
    foreignCurrencyAmount: 0,
    currency: CurrencyEnum.INR,
    comment: '',
    expenseTotalAmount: 0,
    amountInserted: 0,
    statusContent: '',
    expense: '',
    transactionDate: '',
    vendorName: '',
    expenseLocation: '',
    paymentTypeName: '',
    expenseCurrency: '',
    travelReason: '',
    expenseComment: '',
    noReceiptReason: '',
    state: ReportStateEnum.DRAFT,
    startDate: '',
    endDate: '',
    costCenterName: '',
    subDepartmentName: '',
    departmentName: ''
  },
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: '',
  departmentList: [],
  subDepartmentList: [],
  departmentForm: {
    _id: '',
    department: ''
  },
  availabeExpenseList: [],
  availabeExpenseForm: {
    _id: '',
    title: '',
    receiptRequired: ReceiptEnum.NO,
    expenseSearch: '',
    validityDays: 0
  },
  departmentData: {
    _id: '',
    department: '',
    departmentName: '',
    subDepartment: '',
    subDepartmentName: ''
  },
  employeeId: '',
  reportNumber: '',
  state: ReportStateFilterEnum.All,
  approverList: [],
  expenseLoading: false,
  idArray: [],
  downLoadList: [],
  currentErrorField: [],
  reportCountData: {
    manageReport: 0,
    myClaim: 0,
    myClaimLibrary: 0
  },
  selectExpenseType:[],
  name:"",
  QueryListData:[]
};

export const useClinicSlice = createSlice({
  name: 'reportState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setExpenseLoading: (state, action: PayloadAction<boolean>) => {
      state.expenseLoading = action.payload;
    },
    setSelectExpenseType: (state, action: PayloadAction<string[]>) => {
      state.selectExpenseType = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setEmployeeId: (state, action: PayloadAction<string>) => {
      state.employeeId = action.payload;
    },
    setDownLoadList: (state, action: PayloadAction<[]>) => {
      state.downLoadList = action.payload;
    },
    setCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (!state.currentErrorField.includes(action.payload)) {
        state.currentErrorField.push(action.payload);
      }
    },
    removeCurrentErrorField: (state, action: PayloadAction<number>) => {
      if (state.currentErrorField.includes(action.payload)) {
        let index = state.currentErrorField.indexOf(action.payload);
        state.currentErrorField.splice(index, 1);
      }
    },
    clearCurrentErrorField: (state) => {
      state.currentErrorField = [];
    },
    setReportNumber: (state, action: PayloadAction<string>) => {
      state.reportNumber = action.payload;
    },
    setState: (state, action: PayloadAction<ReportStateFilterEnum>) => {
      state.state = action.payload;
    },

    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setIdArray: (state, action: PayloadAction<any>) => {
      state.idArray.push(action.payload);
    },
    removeIdArray: (state, action: PayloadAction<any>) => {
      state.idArray.splice(action.payload, 1);
    },
    clearForm: (state) => {
      state.form._id = '';
      state.form._id = '';
      state.form.name = '';
      state.form.date = '';
      state.form.currency = CurrencyEnum.INR;
      state.form.country = CountryEnum.INDIA;
      state.form.costCenter = '';
      state.form.department = '';
      state.form.subDepartment = '';
      state.form.businessPurpose = '';
      state.form.amountAdjusted = 0;
      state.form.foreignCurrencyAmount = 0;
      state.form.comment = '';
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetListOrganization: (state) => {},
    doGetDownloadsList: (state) => {},
    doApproveReports: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setList: (state, action: PayloadAction<Array<ReportInterface>>) => {
      state.list = action.payload;
    },
    clearIdArray: (state) => {
      state.idArray = [];
    },
    setDataForEdit: (state, action: PayloadAction<ReportInterface>) => {
      state.form = action.payload;
    },

    doGetDepartmentList: (state) => {},
    setDepartmentList: (state, action: PayloadAction<Array<DepartmentInterface>>) => {
      state.departmentList = action.payload;
    },

    doGetSubDepartmentList: (state, action: PayloadAction<string>) => {},
    setSubDepartmentList: (state, action: PayloadAction<Array<SubDepartmentInterface>>) => {
      state.subDepartmentList = action.payload;
    },
    clearDepartmentForm: (state) => {
      state.departmentForm._id = '';
      state.departmentForm.department = '';
    },

    updateExpenseFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `availabeExpenseForm.${action.payload.key}`, action.payload.value);
    },
    // doAddAvailableExpenses: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetAvailableExpensesList: (state) => {},
    doGetAvailableExpensesEdit: (state, action: PayloadAction<{ id: string; callback: any }>) => {},
    setAvailableExpensesList: (state, action: PayloadAction<Array<AvailableExpenseInterface>>) => {
      state.availabeExpenseList = action.payload;
    },
    setAvailableExpensesDataForEdit: (state, action: PayloadAction<AvailableExpenseInterface>) => {
      state.availabeExpenseForm = action.payload;
    },
    clearAvailableExpensesForm: (state) => {
      state.availabeExpenseForm._id = '';
      state.availabeExpenseForm.title = '';
      state.availabeExpenseForm.receiptRequired = ReceiptEnum.NO;
      state.availabeExpenseForm.expenseSearch = '';
      state.availabeExpenseForm.validityDays = 0;
    },
    doGetDepartmentData: (state) => {},
    setSubDepartmentData: (state, action: PayloadAction<DepartmentDataInterface>) => {
      state.departmentData = action.payload;
    },
    setExpenseSearch: (state, action: PayloadAction<string>) => {
      state.availabeExpenseForm.expenseSearch = action.payload;
    },
    doGetAvailableExpensesListWithoutFilter: (state) => {},
    setAvailableExpensesListWithoutFilter: (state, action: PayloadAction<Array<AvailableExpenseInterface>>) => {
      state.availabeExpenseList = action.payload;
    },
    doGetApproverReportList: (state) => {},
    setApproverReportList: (state, action: PayloadAction<Array<ReportInterface>>) => {
      state.approverList = action.payload;
    },
    updateDateFilterFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    doGetReportCountData: (state) => {},
       doAddQuery: (state) => {},
       doGetQuery: (state) => {},
    setReportCountData: (state, action: PayloadAction<ReportCountDataInterface>) => {
      state.reportCountData = action.payload;
    },
    doCopyReports: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setQueryListData: (state, action: PayloadAction<Array<QueryListDataInterface>>) => {
      state.QueryListData = action.payload;
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { selectCodeVerificationForm, selectForgetPaswordFrom, selectLoginForm, selectUpdatePassword, selectUserEmail } from './selector';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import {
  LoginRequest,
  UpdatePassword,
  ForgetPasswordRequest,
  codeVerifyRequest,
  doGetUserDetails,
  // resendEmailRequest,
  doSendVerifyCodeRequest
} from 'utils copy/request';
import { MeProfileRequest } from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { CodeVerificationInterface, ENUM_ROLE_TYPE, ForgetPasswordInterface, UpdatePasswordInterface } from '../types';
import { toast } from 'react-toastify';

export function* doMeRequest() {
  yield delay(500);
  if (localStorage.getItem('token')) {
    try {
      const response: AxiosResponse = yield call(MeProfileRequest);
      if (response && !response.status) {
        toast.error(response.data.message);
        return;
      }

      yield put(
        actions.setProfileData({
          name: response.data.data.name,
          importDefaultStatus: response.data.data.importDefaultStatus,
          email: response.data.data.email,
          usertype: response.data.data.type,
          profilePic: '',
          costCenter: response?.data?.data?.costCenter,
          costCenterName: response?.data?.data?.costCenterName
        })
      );

      yield put(actions.setisLogin(true));
    } catch (error: any) {
      CatchBlockFunction(error);
    }
  } else {
    yield put(actions.setisLogin(false));
  }
}

export function* loginRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const { username, password } = yield select(selectLoginForm);
  let data = {
    email: username,
    password: password
  };
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(LoginRequest, data);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      toast.error(response.data.message);
      return;
    }

    yield put(
      actions.setProfileData({
        name: '',
        importDefaultStatus: false,
        email: '',
        usertype: ENUM_ROLE_TYPE.ADMIN,
        profilePic: '',
        costCenter: response?.data?.data?.costCenter,
        costCenterName: response?.data?.data?.costCenterName
      })
    );
    yield put(actions.setUpdateToken(response.data.data.accessToken));
    yield call(action?.payload?.callback());
    yield put(actions.getUserProfile());
    yield put(actions.loginSuccess());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdatePasswordRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: UpdatePasswordInterface = yield select(selectUpdatePassword);
  if (form.password.length === 0) {
    toast.error('Please enter new password');
    return;
  }
  if (form.confirmPassword.length === 0) {
    toast.error('Please enter confirm password');
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(UpdatePassword, form);
    yield put(actions.setLoading(false));
    if (!response.data.statusCode) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* ForgePasswordDataRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ForgetPasswordInterface = yield select(selectForgetPaswordFrom);
  if (form.email.length === 0) {
    toast.error('Please enter email');
    return;
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
    toast.error('Please enter valid email');
    return;
  }
  try {
    const response: AxiosResponse = yield call(ForgetPasswordRequest, form);

    if (response.data.status == false) {
      toast.error(response.data.message);
      return;
    } else {
      toast.success(response.data.message);
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetDetailsRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetUserDetails, `?token=${action.payload.id}`);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setUserEmail(response.data.data.email));
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doVerifyCodeRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: CodeVerificationInterface = yield select(selectCodeVerificationForm);
  if (form.otp.length === 0) {
    toast.error('Please enter verification code');
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(codeVerifyRequest, form);
    yield put(actions.setLoading(false));

    if (response.data.status == false) {
      toast.error(response.data.message);
      return;
    } else {
      toast.success(response.data.message);
    }
    yield put(actions.setUpdateToken(response.data.data.accessToken));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doSendVerificationCodeRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const { username } = yield select(selectLoginForm);
  const userEmail: string = yield select(selectUserEmail);
  let data = {
    email: username ? username : userEmail
  };

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(doSendVerifyCodeRequest, data);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    } else {
      toast.success(response.data.message);
    }
    yield call(action?.payload?.callback(response.data.data.hash));
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

export function* authRepoSaga() {
  yield takeLatest(actions.doLogin, loginRequest);
  yield takeLatest(actions.getUserProfile, doMeRequest);
  yield takeLatest(actions.doUpdatePassword, doUpdatePasswordRequest);
  yield takeLatest(actions.doSendForgetPassword, ForgePasswordDataRequest);
  yield takeLatest(actions.doGetDetails, doGetDetailsRequest);
  yield takeLatest(actions.doVerifyCode, doVerifyCodeRequest);
  // yield takeLatest(actions.doVerifyCode, doGetResendEmailRequest);
  yield takeLatest(actions.doSendVerificationCode, doSendVerificationCodeRequest);
}

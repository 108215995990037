import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';

const SessionTimeout = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState(['click', 'load', 'scroll']);
  const [second, setSecond] = useState(0);
  console.log(second, setEvents);

  let timeStamp;
  let warningInactiveInterval: any = useRef();
  let startTimerInterval: any = useRef();

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp: string | null = sessionStorage.getItem('lastTimeStamp');
      warningInactive(storedTimeStamp);
    }, 20 * 60 * 1000);
  };

  // warning timer
  let warningInactive = (timeString: string | null) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 1; // Maximum ideal time given before logout
      const popTime = 1; // remaining time (notification) left to logout.

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();
      const leftSecond = 60 - diff.seconds();

      if (minPast === popTime) {
        setSecond(leftSecond);
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem('lastTimeStamp');
        window.localStorage.removeItem('token');
        navigate('/login');
      }
    }, 1000);
  };

  // reset interval timer
  let token = window.localStorage.getItem('token');
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (token) {
      timeStamp = String(moment());
      sessionStorage.setItem('lastTimeStamp', timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem('lastTimeStamp');
    }

    timeChecker();
  }, [token]);

  // Life cycle hook
  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    // Run the timeChecker
    timeChecker();
    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);
  return <Fragment />;
};

export default SessionTimeout;

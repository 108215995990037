export interface SubDepartmentInterface {
  id: string;
  _id:string;
  departmentId: string;
  department: string;
  subDepartment: string;
  isActive: IsActivityEnum;
}
export interface DepartmentAllInterface {
  _id:string;
  department: string;
}
export enum IsActivityEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export interface SubDepartmentState {
  list: SubDepartmentInterface[];
  departmentList: DepartmentAllInterface[];
  form: SubDepartmentInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  currentErrorField: number[];
}

export type InitialSubDepartmentState = SubDepartmentState;

import { CountryEnum } from 'pages/report/types';
import { ENUM_ROLE_TYPE } from 'sections/auth/types';

export interface MyClaimInterface {
  id: string;
  _id: string;
  name: string;
  date: string;
  country: CountryEnum;
  department: string;
  departmentName: string;
  subDepartment: string;
  subDepartmentName: string;
  statusContent: string;
  costCenter: string;
  businessPurpose: string;
  amountAdjusted: number;
  foreignCurrencyAmount: number;
  currency: CurrencyEnum;
  comment: string;
  state: ReportStateEnum;
  reportNumber: string;
  startDate: any;
  endDate: any;
}
export enum CurrencyEnum {
  INR = 'INR',
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  YEN = 'YEN'
}

export enum ReportStateEnum {
  DRAFT = 1,
  SUBMIT = 2,
  INPROGRESS = 3,
  APPROVED = 4
}
export enum ReceiptEnum {
  NO = 0,
  YES = 1
}
export enum ReportStateFilterEnum {
  All = 0,
  DRAFT = 1,
  SUBMIT_AND_PENDING_APPROVAL = 2,
  APPROVED_AND_IN_ACCOUNTING_REVIEW = 4,
  APPROVED = 7,
  SENT_FOR_PAYMENT = 8
}
export interface MyClaimState {
  list: MyClaimInterface[];
  topSpenderList: any;
  form: MyClaimInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  employeeId: string;
  reportNumber: string;
  state: ReportStateFilterEnum;
  roleState: ENUM_ROLE_TYPE;
  sentForPaymentList:MyClaimInterface[];
}

export type InitialMyClaimState = MyClaimState;

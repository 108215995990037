import { ENUM_ROLE_TYPE } from 'sections/auth/types';

export interface ExpensesInterface {
  id: string;
  _id: string;
  title: string;
  expneseLevelName: string;
  receiptRequired: ReceiptEnum;
  isActive: IsActivityEnum;
  grade: string;
  gradeId: string;
  expenseGradeForm: GradeInterface;
  expenseGrades: GradeInterface[];
  validityDays: number;
  gradeSystemApplied: gradeSystemAppliedEnum;
  expneseLevelId: string;
  glCode: string;
  levelBypass: LevelBypassEnum;
}

export interface GradeInterface {
  id: string;
  _id: string;
  grade?: string;
  gradeId?: string;
  maxPrice?: number;
  action: string;
}
export interface ReportExpensesInterface {
  id: string;
  _id: string;
  expenseId: string;
  reportId: string;
  expense: string;
  transactionDate: string;
  fileName:string;
  vendorName: string;
  location: string;
  paymentType: PaymentTypeEnum;
  amount: number;
  currency: CurrencyEnum;
  receiptRequired: ReceiptRequiredEnum;
  travelReason: string;
  comment: string;
  state: ReportStateEnum;
  receiptFile: string;
  noReceiptReason: string;
  validityDays: number;
  message: string;
  expenseAmount: number;
}
export enum ReceiptEnum {
  NO = 0,
  YES = 1
}

export enum PaymentTypeEnum {
  CASH = 1,
  DIGITAL = 2
}
export enum IsActivityEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export enum ReceiptRequiredEnum {
  NO_RECEIPT = 0,
  RECEIPT = 1
}
export enum CurrencyEnum {
  INR = 'INR',
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  YEN = 'YEN'
}
export enum ReportStateEnum {
  DRAFT = 1,
  SUBMIT = 2,
  INPROGRESS = 3,
  APPROVED = 4,
  RECALL = 5,
  REJECT = 6,
  CLAIM = 7,
  SENT_FOR_PAYMENT = 8,
  APPROVED_BY_PROCESSOR = 9
}
export enum gradeSystemAppliedEnum {
  NO = 0,
  YES = 1
}
export enum LevelBypassEnum {
  NO = 0,
  YES = 1
}
export interface ReportDataInterface {
  _id: string;
  report: string;
  reportNumber: string;
  statusContent: string;
  expenseTotalAmount: number;
  state: ReportStateEnum;
  role: ENUM_ROLE_TYPE;
  myReport: boolean;
  attachmentCount: number;
}
export interface CommentListInterface {
  createdAt: string;
  name: string;
  message: string;
  role: string;
}
export interface sendBackToEmployeeInterface {
  _id: string;
  message: string;
}
export interface ApproverFlowListInterface {
  name: string;
}
export interface ExpensesState {
  list: ExpensesInterface[];
  form: ExpensesInterface;
  reportExpenseForm: ReportExpensesInterface;
  reportExpenseList: ReportExpensesInterface[];
  reportData: ReportDataInterface;
  reportCommentList: CommentListInterface[];
  expenseCommentList: CommentListInterface[];
  sendBackToEmployeeForm: sendBackToEmployeeInterface;
  approverFlowList: ApproverFlowListInterface[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  anotherLoading: boolean;
  expenseLoading: boolean;
  reportPageNo: number;
  reportPageSize: number;
  reportTotalRow: number;
  expensePageNo: number;
  expensePageSize: number;
  expenseTotalRow: number;
  reportHasMore: boolean;
  expenseHasMore: boolean;
  modalLoading: boolean;
  currentErrorField: number[];
  commentLog: any;
  commentLogPageNo: number;
  commentLogPageSize: number;
  commentLogTotalRow: number;
  commentLogHasMore: boolean;
  pdfContent : string[];
  pdfName:string
}

export type InitialExpensesState = ExpensesState;

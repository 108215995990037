export interface LevelInterface {
  id: string;
  _id: string;
  title: string;
  isActive: IsActivityEnum;
  isMandatory: IsMandatoryEnum;
  order: number;
}
export enum IsActivityEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export enum IsMandatoryEnum {
  NO = 0,
  YES = 1
}

export interface LevelState {
  list: LevelInterface[];
  form: LevelInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  currentErrorField: number[];
}

export type InitialLevelState = LevelState;

export interface EmployeeInterface {
  id: string;
  _id:string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  dob: string;
  dateOfJoining: string;
  email: string;
  mobileNumber: string;
  address: string;
  location: string;
  region: string;
  role: ENUM_ROLE_TYPE;
  designation: string;
  designationName: string;
  isActive: IsActivityEnum;
  gender: GenderEnum;
  zonal: string;
  band: string;
  level: string;
  approver: string;
  levelApprover: LevelApproverInterface[];
  levelApproverForm: LevelApproverInterface;
  department: string;
  subDepartment: string;
  grade: string;
  gradeId: string;
  employeeID: string;
  terminationDate: string;
  costCenter: string;
}
export enum IsActivityEnum {
  INACTIVE = 2,
  ACTIVE = 1
}
export enum GenderEnum {
  FEMALE = 0,
  MALE = 1,
}
export interface DesignationtInterface {
  _id: string;
  designation: string;
}

export enum ENUM_ROLE_TYPE {
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  ORGANIZATION_MANAGER = 'ORGANIZATION_MANAGER',
  EMPLOYEE= 'EMPLOYEE',
}
export interface AllLevelInterface {
  _id:string;
  title: string;
}
export interface ApproverListInterface {
  _id:string;
  name: string;
}
export interface LevelApproverInterface {
  levelId: string;
  approverId: string;
  title: string;
  name :string;
  index :number
}
export interface EmployeeState {
  list: EmployeeInterface[];
  designationlist: DesignationtInterface[];
  allLevelList: AllLevelInterface[];
  approverlist: ApproverListInterface[];
  form: EmployeeInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  currentErrorField: number[];
}

export type InitialEmployeeState = EmployeeState;

import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { SubDepartmentInterface } from '../types';
import { selectForm, SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import {
  doAddSubDepartment,
  doGetSubDepartmentList,
  doGetSubDepartmentEdit,
  doUpdateSubDepartment,
  doGetDepartmentAll
} from 'utils copy/request';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { toast } from 'react-toastify';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: SubDepartmentInterface = yield select(selectForm);

  if (form.departmentId.length === 0 || form.subDepartment.length === 0) {
    if (form.departmentId.length === 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please select department name');
    }
    if (form.subDepartment.length === 0) {
      yield put(actions.setCurrentErrorField(2));
      toast.error('Please fill sub department name');
    }
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddSubDepartment, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    const response: AxiosResponse = yield call(
      doGetSubDepartmentList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTotalRow(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setList(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetSubDepartmentEdit, action.payload);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: SubDepartmentInterface = yield select(selectForm);

  if (form.departmentId.length === 0 || form.subDepartment.length === 0) {
    if (form.departmentId.length === 0) {
      yield put(actions.setCurrentErrorField(1));
      toast.error('Please select department name');
    }
    if (form.subDepartment.length === 0) {
      yield put(actions.setCurrentErrorField(2));
      toast.error('Please fill sub department name');
    }
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateSubDepartment, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetDepartmentAllRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetDepartmentAll);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setDepartmentAllList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* SubDepartmentRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doGetDepartmentAllList, doGetDepartmentAllRequest);
}

import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { AddRoleState, UpdateProfileInterface } from '../types';

export const initialState: AddRoleState = {
  loading: false,
  updatePasswordList: [],
  updatePasswordFormData: {
    id: '',
    password: '',
    confirmPassword: ''
  },
  editProfileList: [],
  editProfileForm: {
    name: '',
    address: '',
    mobileNumber: '',
    profilePic: '',
    email: ''
  }
};

export const useClinicSlice = createSlice({
  name: 'AddRoletate',
  initialState,
  reducers: {
    userUpdatePasswordFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `updatePasswordFormData.${action.payload.key}`, action.payload.value);
    },
    doUpdateUserPassword: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearUpdatePasswordForm: (state) => {
      state.updatePasswordFormData.id = '';
      state.updatePasswordFormData.password = '';
      state.updatePasswordFormData.confirmPassword = '';
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      console.log('Loading');
      state.loading = action.payload;
    },
    userEdit: (state, action: PayloadAction<any>) => {},

    doGetEditProfile: (state) => {},
    UpdateProfileFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `editProfileForm.${action.payload.key}`, action.payload.value);
    },
    doUpdateEditProfile: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearEditProfileForm: (state) => {
      state.editProfileForm.name = '';
      state.editProfileForm.email = '';
      state.editProfileForm.address = '';
      state.editProfileForm.mobileNumber = '';
      state.editProfileForm.profilePic = '';
    },
    doGetEdit: (state, action: PayloadAction<any>) => {},
    setList: (state, action: PayloadAction<Array<UpdateProfileInterface>>) => {
      state.editProfileList = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<UpdateProfileInterface>) => {
      state.editProfileForm = action.payload;
    },
    doAddImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    doUploadeImage: (state, action: PayloadAction<{  data: any; signedUrl: string; result : any;callback: any }>) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;

import axios from 'axios';
import Api from './Api';
const LoginRequest = async (data: any) => {
  return Api.post(`admin/login`, data);
};
const MeProfileRequest = async () => {
  return Api.get(`auth/user/me`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdatePassword = async (data: any) => {
  return Api.patch(`/admin/change-password`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const EditProfileRequest = async () => {
  return Api.get(`auth/user/profile`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateProfileRequest = async (data: any) => {
  return Api.patch(`/auth/user/profile/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const ForgetPasswordRequest = async (data: any) => {
  return Api.post(`public/user/forgot-password`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doSendVerifyCodeRequest = async (data: any) => {
  return Api.post(`auth/user/send-otp`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const codeVerifyRequest = async (data: any) => {
  return Api.post(`auth/user/verify-otp`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetUserDetails = async (id: string) => {
  return Api.get(`auth/user/get-detail${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetDashboardlistRequest = async (id: string) => {
  return Api.get(`dashboard/employee`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};


const resendEmailRequest = async (data: any) => {
  return Api.post(`public/user/forgot-password`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetProfilePicRequest = async (data: any) => {
  return Api.post(
    `/auth/user/signed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};

const doUplodeProfilePicRequest = async (signedUrl: any, data: any, contentType: any) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      'Content-Type': contentType
    }
  });
};
//organization section start//
const doAddOrganization = async (data: any) => {
  return Api.post(`/organization/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetOrganizationList = async (filter: string) => {
  return Api.get(`/organization/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetOrganizationAnalyticsList = async (filter: string) => {
  return Api.get(`/organization/analytics-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetOrganizationEdit = async (id: string) => {
  return Api.get(`/organization/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateOrganization = async (data: any) => {
  return Api.put(`/organization/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//organization section end//
//expenses section start//
const doAddExpense = async (data: any) => {
  return Api.post(`/expenses/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetExpensesList = async (filter: string) => {
  return Api.get(`/expenses/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetExpenseEdit = async (id: string) => {
  return Api.get(`/expenses/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateExpense = async (data: any) => {
  return Api.put(`/expenses/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddImportDefaultExpenses = async (data: any) => {
  return Api.post(`/expenses/import/default`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetReportCommentList = async (filter: any) => {
  return Api.get(`/report/report/comment/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetExpenseCommentList = async (filter: any) => {
  return Api.get(`/report/report/expense/comment/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//expenses section end//
//designation section start//
const doAddDesignation = async (data: any) => {
  return Api.post(`/designation/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetDesignationList = async (filter: string) => {
  return Api.get(`/designation/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetDesignationEdit = async (id: string) => {
  return Api.get(`/designation/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateDesignation = async (data: any) => {
  return Api.put(`/designation/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetDesignationListAll = async (filter: string) => {
  return Api.get(`/designation/all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//designation section end//
//employee section start//
const doAddEmployee = async (data: any) => {
  return Api.post(`/employee/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetEmployeeList = async (filter: string) => {
  return Api.get(`/employee/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetEmployeeEdit = async (id: string) => {
  return Api.get(`/employee/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateEmployee = async (data: any) => {
  return Api.put(`/employee/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//employee section end//
//report section start//
const doAddReport = async (data: any) => {
  return Api.post(`/report/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddQuery = async (data: any) => {
  return Api.post(`/query/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetQueryList = async () => {
  return Api.get(`/query/list`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doApprovedReport = async (data: any) => {
  return Api.put(
    `/report/multi-submit`,
    {
      reports: data
    },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const doReportCopy = async (data: any) => {
  return Api.put(
    `report/copy`,
    { reportData: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const doGetReportList = async (filter: string) => {
  return Api.get(`/report/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetReportEdit = async (id: string) => {
  return Api.get(`/report/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetReportSummary = async (id: string) => {
  return Api.get(`/report/all/expense/comment/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateReport = async (data: any) => {
  return Api.put(`/report/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetDepartmentRequest = async () => {
  return Api.get(`/report/department`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetSubDepartmentRequest = async (filter: string) => {
  return Api.get(`/report/sub/department${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAvailableExpensesList = async (filter: string) => {
  return Api.get(`/expenses/all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAvailableExpensesList2 = async () => {
  return Api.get(`/expenses/all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMyReportList = async (filter: string) => {
  return Api.get(`/report/my-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetTopSpenderList = async (filter: string) => {
  return Api.get(`/report/top-spender-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetMyApprovedReportList = async (filter: string) => {
  return Api.get(`/report/my-sent-for-payment-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAvailableExpensesListData = async (id: string) => {
  return Api.get(`/expenses/detail/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetDepartmentData = async () => {
  return Api.get(`/report/user-department`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetApproverReportList = async (filter: string) => {
  return Api.get(`/report/approver-report-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetDownloadList = async (filter: string) => {
  return Api.get(`/report/get-report-payment-file${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetExcelDownload = async () => {
  return Api.get(`/report/export-payment-list`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetReportCount = async () => {
  return Api.get(`report/report-list-count`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//report section end//
//reporting section start//
const doAddReporting = async (data: any) => {
  return Api.post(`/reporting/expense/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetReportingList = async (filter: string) => {
  return Api.get(`/reporting/expense/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetReportingEdit = async (id: string) => {
  return Api.get(`/reporting/expense/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateReporting = async (data: any) => {
  return Api.put(`/reporting/expense/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetReceiptImageRequest = async (data: any) => {
  return Api.post(
    `/reporting/expense/singed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};

const doCopyExpense = async (id: any) => {
  return Api.put(
    `/reporting/expense/copy/${id}`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const doSubmitReport = async (id: any) => {
  return Api.put(
    `/report/submit/${id}`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const doDeleteOrganization = async (id: any) => {
  return Api.delete(`/organization/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doDeleteExpense = async (id: any) => {
  return Api.delete(`/reporting/expense/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doRecallReport = async (id: any) => {
  return Api.put(
    `/report/recall/${id}`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const doSendBackToEmployee = async (data: any) => {
  return Api.put(`/report/send-back/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAppoverFlowList = async (id: any) => {
  return Api.get(`/report/approver/list/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//reporting section end//
//department section start//
const doAddDepartment = async (data: any) => {
  return Api.post(`/department/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetDepartmentList = async (filter: string) => {
  return Api.get(`/department/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetDepartmentEdit = async (id: string) => {
  return Api.get(`/department/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateDepartment = async (data: any) => {
  return Api.put(`/department/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetDepartmentAll = async () => {
  return Api.get(`/department/all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//department section end//
//subdepartment section start//
const doAddSubDepartment = async (data: any) => {
  return Api.post(`/sub/department/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetSubDepartmentList = async (filter: string) => {
  return Api.get(`/sub/department/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetSubDepartmentEdit = async (id: string) => {
  return Api.get(`/sub/department/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateSubDepartment = async (data: any) => {
  return Api.put(`/sub/department/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//subdepartment section end//
//level section start//
const doAddLevel = async (data: any) => {
  return Api.post(`/level/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetLevelList = async (filter: string) => {
  return Api.get(`/level/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetLevelEdit = async (id: string) => {
  return Api.get(`/level/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateLevel = async (data: any) => {
  return Api.put(`/level/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAllLevel = async () => {
  return Api.get(`/level/get-all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetApproverList = async (filter: any) => {
  return Api.get(`/employee/get-approver${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//level section end//

//excel import
const doExcelImportRequest = async (data: any) => {
  return Api.post(
    `/employee/import-excel`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};

const doAddExcelImport = async (data: any) => {
  return Api.post(`/employee/upload-excel-file`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetExcelList = async (filter: string) => {
  return Api.get(`/employee/imported/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetExcelListById = async (id: string, filter: string) => {
  return Api.get(`/employee/get/imported/file/${id}${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//excel import
//Grade section start//
const doAddGrade = async (data: any) => {
  return Api.post(`/grades/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetGradeList = async (filter: string) => {
  return Api.get(`/grades/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetGradeEdit = async (id: string) => {
  return Api.get(`/grades/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateGrade = async (data: any) => {
  return Api.put(`/grades/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAllGrade = async () => {
  return Api.get(`/grades/all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}; //Grade section end//
//Grade section end//
//expense Level section start//
const doAddExpenseLevel = async (data: any) => {
  return Api.post(`/expense-level/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetExpenseLevelList = async (filter: string) => {
  return Api.get(`/expense-level/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetExpenseLevelEdit = async (id: string) => {
  return Api.get(`/expense-level/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateExpenseLevel = async (data: any) => {
  return Api.put(`/expense-level/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAllExpenseLevel = async () => {
  return Api.get(`/expense-level/all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//expense Level section end//
//Cost Center start//
const doAddCostCenter = async (data: any) => {
  return Api.post(`cost-center/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetCostCenterList = async (filter: string) => {
  return Api.get(`cost-center/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetCostCenterEdit = async (id: string) => {
  return Api.get(`cost-center/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateCostCenter = async (data: any) => {
  return Api.put(`cost-center/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetAllCostCenter = async () => {
  return Api.get(`cost-center/all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetReportOrganizationList = async (filter: string) => {
  return Api.get(`/report/report-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//Cost Center end//
const doGetPdfDownload = async (id: string) => {
  return Api.get(`/report/export-report-receipt/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
export {
  doGetPdfDownload,
  LoginRequest,
  MeProfileRequest,
  UpdatePassword,
  ForgetPasswordRequest,
  doSendVerifyCodeRequest,
  codeVerifyRequest,
  doGetUserDetails,
  resendEmailRequest,
  EditProfileRequest,
  UpdateProfileRequest,
  doGetProfilePicRequest,
  doUplodeProfilePicRequest,
  doAddOrganization, //organization
  doGetOrganizationList,
  doGetOrganizationEdit,
  doUpdateOrganization,
  doAddExpense,
  doGetExpensesList,
  doGetExpenseEdit,
  doUpdateExpense,
  doAddImportDefaultExpenses,
  doAddDesignation,
  doGetDesignationList,
  doGetDesignationEdit,
  doUpdateDesignation,
  doGetDesignationListAll,
  doAddEmployee,
  doGetEmployeeList,
  doGetEmployeeEdit,
  doUpdateEmployee,
  doAddReport,
  doGetReportList,
  doGetReportEdit,
  doUpdateReport,
  doGetAvailableExpensesList,
  doGetDepartmentRequest,
  doGetSubDepartmentRequest,
  doAddDepartment,
  doGetDepartmentList,
  doGetDepartmentEdit,
  doUpdateDepartment,
  doGetDepartmentAll,
  doAddSubDepartment,
  doGetSubDepartmentList,
  doGetSubDepartmentEdit,
  doUpdateSubDepartment,
  doAddReporting,
  doGetReportingList,
  doGetReportingEdit,
  doUpdateReporting,
  doGetMyReportList,
  doGetAvailableExpensesListData,
  doGetReceiptImageRequest,
  doAddLevel,
  doGetLevelList,
  doGetLevelEdit,
  doUpdateLevel,
  doGetAllLevel,
  doGetApproverList,
  doCopyExpense,
  doGetDepartmentData,
  doSubmitReport,
  doGetReportSummary,
  doDeleteExpense,
  doRecallReport,
  doGetAvailableExpensesList2,
  doExcelImportRequest,
  doAddExcelImport,
  doAddGrade,
  doGetGradeList,
  doGetGradeEdit,
  doUpdateGrade,
  doGetAllGrade,
  doAddExpenseLevel,
  doGetExpenseLevelList,
  doGetExpenseLevelEdit,
  doUpdateExpenseLevel,
  doGetAllExpenseLevel,
  doGetExcelList,
  doGetExcelListById,
  doGetReportCommentList,
  doGetExpenseCommentList,
  doGetApproverReportList,
  doSendBackToEmployee,
  doAppoverFlowList,
  doGetExcelDownload,
  doGetReportCount,
  doDeleteOrganization,
  doApprovedReport,
  doReportCopy,
  doGetDownloadList,
  doGetMyApprovedReportList,
  doAddCostCenter,
  doGetCostCenterList,
  doGetCostCenterEdit,
  doUpdateCostCenter,
  doGetAllCostCenter,
  doGetReportOrganizationList,
  doGetTopSpenderList,
  doGetOrganizationAnalyticsList,
  GetDashboardlistRequest,
  doAddQuery,
  doGetQueryList
};

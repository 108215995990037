import dashboard from './dashboard';
import { NavItemType } from 'types/menu';
import custom from './custom';
import { ENUM_ROLE_TYPE } from 'sections/auth/types';
import customOrganisation from './customOrganisation';
import customOrganisationAdmin from './customOrganisationAdmin';
import customEmployee from './customEmployee';
import customManager from './customManager';

// ==============================|| MENU ITEMS ||============================== //

const menuItemss = (Role?: ENUM_ROLE_TYPE): { items: NavItemType[] } => {

  const menuItems: { items: NavItemType[] } = {
    // items:    [dashboard, custom]
    items:
    Role == ENUM_ROLE_TYPE.ADMIN
      ? [dashboard, custom]
      : Role == ENUM_ROLE_TYPE.ORGANIZATION
      ? [dashboard, customOrganisation]
      : Role == ENUM_ROLE_TYPE.ORGANIZATION_ADMIN
      ? [dashboard, customOrganisationAdmin]
      : Role == ENUM_ROLE_TYPE.ORGANIZATION_MANAGER
      ? [dashboard, customManager]
      : Role == ENUM_ROLE_TYPE.EMPLOYEE
      ? [dashboard, customEmployee]
      : []
  };

  return menuItems;
};

export default menuItemss;
